import React from 'react';
import './modal-header.scss';

interface IModalHeaderProps {
  closeHandler: Function;
}

export default function ModalHeader(props: IModalHeaderProps) {
  const { closeHandler } = props;
  return (
    <div className="modal-header-container">
      <span className="book-an-appointment">Book an appointment</span>
      <button type="button" className="close-button" onClick={() => closeHandler()}>
        <i className="fal fa-times" title="Close book an appointment" />
      </button>
    </div>
  );
}
