import React from 'react';
import { PracticeLocation } from '../../types/locations';
import './clinic-address.scss';

interface IClinicAddressProps {
  location: PracticeLocation;
  isSnippet?: boolean;
  indexNumber?: number;
}

function ClinicAddress(props: IClinicAddressProps) {
  const { location, isSnippet, indexNumber } = props;
  return (
    <div id={`location-card-address-${indexNumber}`} className="clinic-address">
      { !isSnippet && (<i className="fa fa-map-marker-alt" />)}
      <div className="address-lines">
        <div className="address-block">
          <div>{location?.streetAddress}</div>
          <div>{location?.cityStateZip}</div>
        </div>
      </div>
    </div>
  );
}

export default ClinicAddress;
