import React from 'react';
import './section-paragraph-phone.scss';

function SectionParagraphWithPhone() {
  return (
    <p className="section-paragraph-phone">
      Online scheduling is available for sick visits.  If you need to schedule an appointment for a physical or mental health visit, please call the Student Health Clinic at
      {' '}
      <a id="ksu-information-phone-number" data-id="ksu-details-phone-number" href="tel:+1(470) 578-6644}">
        (470) 578-6644
      </a>
      .
    </p>
  );
}

export default SectionParagraphWithPhone;
