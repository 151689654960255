import React from 'react';
import { PracticeLocation } from '../../types/locations';
import './LocationContact.scss';

interface ILocationContactProps {
  location: PracticeLocation;
  indexNumber?: number;
}

export default function LocationContact(props: ILocationContactProps) {
  const { location, indexNumber } = props;
  return (
    <div>
      {typeof location.locationContactPhone !== 'string' || location.locationContactPhone.length === 0 ? null : (
        <div className="location-phone">
          <div>
            <a id={`location-card-contact-phone-${indexNumber}`} className="location-contact-phone" data-id="location-ksu-details-phone-number" href={`tel:+1${location.locationContactPhone}`}>
              {location.locationContactPhone}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
