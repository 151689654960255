import { IPhysicianSearchResponse } from '../../types/physicians';

/* eslint-disable arrow-body-style */
const PhysicianProfileTemplateId = '8406b10878a944a4bd6ae838d6cbb1ed';

async function physicianSearch(): Promise<IPhysicianSearchResponse> {
  const params = {
    searchTerm: '',
    distance: 5,
    location: 'Kennesaw State University, Chastain Road Northwest, Kennesaw, GA, USA',
    near: '34.03807839999999,-84.5815717',
  };

  const searchRequest = {
    q: params.searchTerm,
    facet: PhysicianProfileTemplateId,
    filters: [], // No logic for parsing filter strings
    near: params.near,
    near2: '',
    distance: params.distance,
    randomSort: null,
    skip: 0,
    take: 500,
    searchMode: 1,
  };

  const queryResponse = await fetch('/api/search/query', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(searchRequest),
  });

  const queryResults = await queryResponse.json();

  const npidList = queryResults.matchingItems
    .map((item: any) => item.NPID)
    .join(',');

  const scheduleResponse = await fetch(`api/scheduling/providers/providerschedules?providerIds=${npidList}`, {
    method: 'GET',
  });
  const scheduleResults = await scheduleResponse.json();

  scheduleResults.forEach((schedule: any) => {
    queryResults.matchingItems.find((phys: any) => {
      return phys.NPID === schedule.provider.iDs.find((id: any) => {
        return id.type === 'NPI';
      }).id;
    }).schedule = schedule;
  });

  return queryResults;
}

export default physicianSearch;
