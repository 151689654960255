import React from 'react';
import PractitionersContainer from './practitioners-container';
import SectionTitle from '../section-title';
import SectionParagraph from '../section-paragraph';
import BackToTop from '../back-to-top';
import './nearby-practitioners.scss';

function NearbyPractitioners() {
  return (
    <section className="nearby-practitioners" id="nearby-practitioners">
      <div className="container">
        <div className="col-12 text-start">
          <SectionTitle title="Wellstar Providers Nearby" />
        </div>
        <div className="col-12 text-start">
          <SectionParagraph paragraph="KSU students can also see Wellstar providers outside of student health services.
            With more than 1,000 providers across 80 different specialties, if you need more holistic, or specialty care
            such as cardiology or neurology, you can always find expert care close to campus.
            Use the map below to see additional primary care providers in a 5-mile radius of the main Kennesaw campus."
          />
        </div>
        <div className="col-12">
          <PractitionersContainer />
        </div>
        <div className="col-12">
          <BackToTop />
        </div>
      </div>
    </section>
  );
}

export default NearbyPractitioners;
