import React from 'react';
import PropTypes from 'prop-types';
import './section-paragraph.scss';

interface ISectionParagraphProps {
  paragraph: string;
}

function SectionParagraph(props: ISectionParagraphProps) {
  const { paragraph } = props;
  return (
    <p className="section-paragraph">{paragraph}</p>
  );
}

SectionParagraph.propTypes = {
  paragraph: PropTypes.string.isRequired,
};

export default SectionParagraph;
