import React, { useEffect, useRef } from 'react';
import { PhysicianResult } from '../../types/physicians';
import { PhysicianCard } from '../physician-card/PhysicianCard';
import { PracticeLocation } from '../../types/locations';

interface IPractitionersListProps {
  physicians: PhysicianResult[];
  scrollToLocation?: PracticeLocation;
  doneScrolling: Function;
}

export default function PractitionersList(props: IPractitionersListProps) {
  const { physicians, scrollToLocation, doneScrolling } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    cardsRef.current = cardsRef.current.slice(0, physicians.length);
  }, [physicians]);

  useEffect(() => {
    if (scrollToLocation) {
      // Search for the first card with an address that matches
      const index = physicians.findIndex((physician) => physician.matchedLocations.some((location) => location.address === scrollToLocation.address));
      if (index >= 0 && containerRef.current) {
        const containerOverflow = window.getComputedStyle(containerRef.current).overflow;
        if (containerOverflow === 'auto') {
          // Desktop mode
          const cardTop = cardsRef.current[index]?.offsetTop;
          const containerTop = containerRef.current?.offsetTop;
          if (cardTop !== undefined && containerTop !== undefined) {
            containerRef.current?.scrollTo({ top: cardTop - containerTop, behavior: 'smooth' });
            doneScrolling();
          }
        } else {
          // Mobile mode
          cardsRef.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          doneScrolling();
        }
      }
    }
  }, [scrollToLocation]);

  return (
    <div className="practitioners-list-container" ref={containerRef}>
      {physicians.map((physician, i) => (
        <div
          className="practitioner-item"
          key={physician.physician.fullName}
          ref={(elem) => { cardsRef.current[i] = elem; }}
        >
          <PhysicianCard
            physician={physician}
            togglePatientScheduling
            isVirtualVisitSearchEnabled={false}
          />
        </div>
      ))}
    </div>
  );
}
