import React, { useRef } from 'react';
import { PracticeLocation } from '../../types/locations';
import { IProfileLocation } from '../../types/profile-locations';
import { locationToDepartment, SchedulingInput } from '../../types/schedules';
import ScheduleLauncher, { IScheduleLauncher } from '../scheduler-launcher';
import './clinic-practitioner-availability.scss';

interface IClinicPractitionerAvailabilityProps {
  location: IProfileLocation;
  isVirtualVisit: boolean;
}

function ClinicPractitionerAvailability(props: IClinicPractitionerAvailabilityProps) {
  const { location, isVirtualVisit } = props;
  const launcherRef = useRef<IScheduleLauncher>(null);

  const showPractitionerAvailability = () => {
    launcherRef?.current?.show().then(({ isCompleted, questionnaireData }) => {
      if (!isCompleted) return;
      if (!location || typeof (window as any).initSchedulingHelper !== 'function') return;
      if (!location.ListOfPhysician) return;

      const practice = new PracticeLocation(location);
      const dept = locationToDepartment(practice);

      // Need to manually set up the associatedPhysicians that OSW expects.
      // They are provided in Search API but not LocationDetails API.
      dept.providers = location.ListOfPhysician.map((physician) => ({
        name: '',
        image: null,
        pageUrl: physician.PageUrl,
      }));

      const input = new SchedulingInput('location', {
        isKSU: true,
        location: dept,
        isVirtualVisit: false,
        visitTypeId: '855',
        additionalComments: questionnaireData,
        contactPhoneNumber: dept?.phones?.at(0)?.number,
      });
      (window as any).initSchedulingHelper(input);
    });
  };

  return (
    !isVirtualVisit && (
      <div className="practitioner-availability-container">
        <button
          type="button"
          data-id="practitioner-availability-button"
          className="launch-button"
          onClick={showPractitionerAvailability}
        >
          <i className="fa fa-user-clock" />
          Practitioner Availability
        </button>
        <ScheduleLauncher
          ref={launcherRef}
          location={location}
        />
      </div>
    )
  );
}

export default ClinicPractitionerAvailability;
