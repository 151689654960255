/* eslint-disable */

import { FeatureChip, MatchingGeo, UnsureType } from './common';
import { PracticeLocation } from './locations';
import { LocationSchedule } from './schedules';

export type PhysicianResult = {
  physician: Physician;
  primaryLocation: PracticeLocation;
  matchedLocations: PracticeLocation[];
};

export class Physician {
    constructor(response: IPhysicianSearchResponsePhysician, geos?: MatchingGeo[]) {
        if (response === null || typeof response !== 'object') {
            this.npid = '';
            this.ratings = null;
            this.photoImage = null;
            this.locationInformation = [];
            this.featuredChips = [];
            this.schedule = null;
            this.reviewCount = null;
            this.allowVirtualVisits = null;

            return;
        }

        this.firstName = response.FirstName;
        this.lastName = response.LastName;
        this.npid = response.NPID;

        this.ratings = parseFloat(response.Ratings ?? '');
        if (isNaN(this.ratings)) this.ratings = null;
        else this.ratings = Math.round(this.ratings * 10) / 10;

        this.reviewCount = response.ReviewCount !== undefined && response.ReviewCount !== null ? response.ReviewCount : null;
        this.primarySpecialty = response.PrimarySpecialty;
        this.profession = response.Profession;
        this.isPartOfWellStarGroup = response.IsPartOfWellStarGroup;
        this.enableOnlineScheduling = response.EnableOnlineScheduling === true;
        this.EnableAlternateOnlineScheduling = response.EnableAlternateOnlineScheduling === true;
        this.AlternateOnlineSchedulingMessage = response.AlternateOnlineSchedulingMessage;
        this.allowVirtualVisits = response.AllowVirtualVisits === true;
        this.url = response.UrlField;
        this.pageUrl = response.PageUrl;
        this.isNeuro = response.IsNeuro === true;

        let { PhotoImage, Photo, PhysicianLocations, schedule } = response;
        if (!PhotoImage) PhotoImage = Photo;
        this.schedule = schedule;
        this.photoImage =
            PhotoImage !== null && typeof PhotoImage === 'object' && typeof PhotoImage.ImagePath === 'string' && PhotoImage.ImagePath.length > 0
                ? {
                      imagePath: PhotoImage.ImagePath,
                      imageAlt: PhotoImage.ImageAlt,
                      imageTitle: PhotoImage.ImageTitle,
                  }
                : null;

        if (!Array.isArray(PhysicianLocations) || PhysicianLocations.length === 0) this.locationInformation = [];
        else {
            let distances: { [key: number]: MatchingGeo } = {};
            if (Array.isArray(geos))
                distances = geos.reduce((map, val) => {
                    if (typeof val.geoIndex !== 'number' || typeof val.distance !== 'number') return map;
                    map[val.geoIndex] = val;
                    return map;
                }, distances);

            this.locationInformation = PhysicianLocations.map(
                (l, idx) =>
                    new PracticeLocation(
                        {
                            ItemID: l.ItemID,
                            Address: l.Address,
                            Address2: l.Address2,
                            Directions: l.Directions,
                            Latitude: l.Latitude,
                            Longitude: l.Longitude,
                            Name: l.Name,
                            LocationContactPhone: l.Phone,
                            PageURL: l.Url,
                            EnableOnlineScheduling: l.enableOnlineScheduling,
                            Hours: l.Hours,
                            Open24Hours: l.Open24Hours,
                            KeyDetail: l.KeyDetail,
                            OpenSchedulingDepartmentID: l.OpenSchedulingDepartmentID,
                            FeaturedChips: l.FeaturedChips,
                            AllowVirtualVisits: l.AllowVirtualVisits,
                        },
                        distances[idx]
                    )
            );
        }

        this.featuredChips = (response.FeaturedChips ?? [])
            .filter(x => x !== null && typeof x === 'object')
            .map(chip => {
                return {
                    title: chip?.title,
                    icon:
                        typeof chip?.icon?.imagePath !== 'string' || chip?.icon.imagePath.length === 0
                            ? null
                            : {
                                  imagePath: chip?.icon.imagePath,
                                  imageAlt: chip?.icon.imageAlt,
                                  imageTitle: chip?.icon.imageTitle,
                              },
                    abstract: chip?.abstract,
                    color: chip?.color,
                };
            });
    }

    // Response Props
    firstName: UnsureType<string>;
    lastName: UnsureType<string>;
    npid: string;
    ratings: number | null;
    reviewCount: number | null;
    primarySpecialty: UnsureType<string>;
    profession: UnsureType<string[]>;
    isPartOfWellStarGroup: UnsureType<boolean>;
    enableOnlineScheduling: UnsureType<boolean>;
    allowVirtualVisits: UnsureType<boolean>;
    EnableAlternateOnlineScheduling: UnsureType<boolean>;
    AlternateOnlineSchedulingMessage: UnsureType<string>;
    photoImage: {
        imagePath: UnsureType<string>;
        imageAlt: UnsureType<string>;
        imageTitle: UnsureType<string>;
    } | null;
    url: UnsureType<string>;
    locationInformation: PracticeLocation[];
    featuredChips: FeatureChip[];
    schedule: UnsureType<LocationSchedule> | null;
    isNeuro: UnsureType<boolean> | null;
    pageUrl?: string;

    get fullName(): string {
        let name = this.firstName + ' ' + this.lastName;
        if (Array.isArray(this.profession) && this.profession.length > 0) name += ', ' + this.profession[0];

        return name;
    }

    get profileImgUrl(): string {
        const imgPath = this.photoImage?.imagePath;
        return typeof imgPath === 'string' && imgPath.length > 0
            ? imgPath
            : '/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/search-result-profile';
    }
    get profileImgAlt(): string {
        const imgAlt = this.photoImage?.imageAlt;
        return typeof imgAlt === 'string' && imgAlt.length > 0 ? imgAlt : this.fullName;
    }
}

export interface IPhysicianSearchResponse {
    matchingItems: IPhysicianSearchResponsePhysician[];
    matchingGeo?: MatchingGeo[];
    serverTime?: number;
    TogglePatientScheduling?: boolean;
}

export interface IPhysicianSearchResponsePhysician {
    SpecialtyTitle?: UnsureType<string>;
    FirstName?: UnsureType<string>;
    LastName?: UnsureType<string>;
    NPID: string;
    Ratings?: UnsureType<string>;
    ReviewCount?: UnsureType<number>;
    PrimarySpecialty?: UnsureType<string>;
    Profession?: UnsureType<string[]>;
    IsPartOfWellStarGroup?: UnsureType<boolean>;
    EnableOnlineScheduling?: UnsureType<boolean>;
    EnableAlternateOnlineScheduling?: UnsureType<boolean>;
    AllowVirtualVisits?: UnsureType<boolean>;
    AlternateOnlineSchedulingMessage?: UnsureType<string>;
    PhotoImage?: {
        ImagePath: UnsureType<string>;
        ImageAlt: UnsureType<string>;
        ImageTitle: UnsureType<string>;
    };
    Photo?: {
        ImagePath: UnsureType<string>;
        ImageAlt: UnsureType<string>;
        ImageTitle: UnsureType<string>;
    }
    UrlField?: UnsureType<string>;
    PhysicianLocations?: IPhysicianSearchResponseLocation[];
    FeaturedChips?: UnsureType<FeatureChip>[];
    schedule?: UnsureType<LocationSchedule>;
    IsNeuro?: UnsureType<boolean>;
    PageUrl?: string;
}

export interface IPhysicianSearchResponseLocation {
    Address?: UnsureType<string>;
    Address2?: UnsureType<string>;
    Directions?: UnsureType<string>;
    Hours?: any;
    Open24Hours?: UnsureType<boolean>;
    IsNegated?: UnsureType<boolean>;
    ItemID: string;
    Latitude?: UnsureType<number>;
    Longitude?: UnsureType<number>;
    Name?: UnsureType<string>;
    OpenSchedulingDepartmentID?: any;
    Phone?: UnsureType<string>;
    Specialties?: UnsureType<string[]>;
    Url?: UnsureType<string>;
    enableOnlineScheduling?: UnsureType<boolean>;
    KeyDetail?: UnsureType<{ Text: UnsureType<string>; Url: UnsureType<string>; Target: UnsureType<string>; AriaLabel: UnsureType<string> }>;
    FeaturedChips?: UnsureType<FeatureChip>[];
    AllowVirtualVisits?: UnsureType<boolean>;
}
