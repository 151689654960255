import React from 'react';
import WSLogo from './Inline.svg';
import KSLogo from './ksuLogoWhiteLtrs.png';
import './header.scss';

function Header() {
  return (
    <header id="KSU-Header" className="ksu-header py-3 d-flex border-bottom">
      <div className="container d-flex align-items-center">
        <div className="img-container-wellstar">
          <img src={WSLogo} alt="Wellstar logo" />
        </div>
        <div className="vertical-rule" />
        <div className="img-container-ksu">
          <img src={KSLogo} alt="KSU logo" />
        </div>
      </div>
    </header>
  );
}

export default Header;
