import React from 'react';
import './back-to-top.scss';

function BackToTop() {
  const scrollFunction = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="back-to-top"
      onClick={scrollFunction}
      onKeyDown={scrollFunction}
      role="button"
      tabIndex={0}
    >
      Back to top
      <i className="fa fa-angle-up" />
    </div>
  );
}

export default BackToTop;
