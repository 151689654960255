/* eslint-disable */
import React from 'react';
import styles from './Loading.module.scss';
import './search-loader.scss';

interface ISearchLoaderProps {
    overlay?: boolean;
    customStyles?: React.CSSProperties;
}
export const Loading: React.FunctionComponent<ISearchLoaderProps> = ({ overlay, customStyles }) => {
    return (
        <div className={styles.searchLoader + (overlay === true ? ' ' + styles.overlay : '')} style={customStyles}>
            <div className="search-loader"></div>
        </div>
    );
};
