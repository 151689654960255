/* eslint-disable */
import React, { FunctionComponent } from 'react';
import Context from '../../utils/context';
import { Physician, PhysicianResult } from '../../types/physicians';
import { PracticeLocation } from '../../types/locations';
import { hasAppointments, matchLocationToDepartment, SchedulingInput, Timeslot } from '../../types/schedules';
import { getVirtualVisitPracticeLocation } from '../../utils/virtual-visits';
import { FeatureChipTag } from '../feature-chip-tag/FeatureChipTag';
import { ProfileImage } from '../profile-image/ProfileImage';
import { ScheduleSnippet } from './ScheduleSnippet';
import styles from './PhysicianCard.module.scss';

interface IPhysicianCardProps {
  physician: PhysicianResult;
  togglePatientScheduling: boolean;
  isVirtualVisitSearchEnabled?: boolean;
}

const VirtualVisitsChip = {
  title: 'Virtual Video Visits',
  color: 'F3C300',
  abstract: '',
  icon: {
    imagePath: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
    imageAlt: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
    imageTitle: 'Virtual Visits',
  },
};

export const PhysicianCard: FunctionComponent<IPhysicianCardProps> = props => {
  const { isVirtualVisitSearchEnabled } = props;
  const { physician, primaryLocation, matchedLocations } = props.physician;
  const allLocations = physician?.locationInformation ?? [];
  const defaultImgUrl = '/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/search-result-profile';
  const wellstarBadge = '/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/wmg-badge';

  let location = primaryLocation;

  if (isVirtualVisitSearchEnabled && physician.allowVirtualVisits) {
    location = getVirtualVisitPracticeLocation(primaryLocation, matchedLocations);
  }

  let firstChip = (physician?.featuredChips ?? [])[0];

  if (physician?.allowVirtualVisits) {
    firstChip = VirtualVisitsChip;
  }

  const selectedSlot = (selectedDate: Date, slot: Timeslot) => {
    if (physician.schedule?.departments == undefined || typeof (window as any).initSchedulingHelper !== 'function') return;
    let input = new SchedulingInput('physician', {
      isKSU: true,
      npid: physician.npid,
      schedule: physician.schedule,
      selectedLocation: matchLocationToDepartment(location, physician.schedule?.departments),
      selectedDate: selectedDate,
      selectedTime: slot.arriveByText,
      isVirtualVisit: props.isVirtualVisitSearchEnabled,
      selectedSlotBlockType: slot.slotBlockType,
    });
    (window as any).initSchedulingHelper(input);
  };

  const viewMoreAvailability = () => {
    if (physician.schedule?.departments == undefined || typeof (window as any).initSchedulingHelper !== 'function') return;
    let input = new SchedulingInput('physician', {
      isKSU: true,
      npid: physician.npid,
      schedule: physician.schedule,
      selectedLocation: matchLocationToDepartment(location, physician.schedule?.departments),
      isVirtualVisit: false,
    });
    (window as any).initSchedulingHelper(input);
  };

  return (
        <Context.Consumer>
            {ctx => (
                <>
                    <div className={`${styles.card} ${styles.cardSummary}`}>
                        <div className={styles.cardBody + ' d-flex'}>
                            <div className={styles.cardPhysicianInfo}>
                                <div className={styles.cardImageInfoContainer}>
                                    <ProfileImage
                                        imgUrl={physician.photoImage?.imagePath ?? defaultImgUrl}
                                        imgAlt={physician.fullName}
                                        badgeUrl={physician.isPartOfWellStarGroup ? wellstarBadge : undefined}
                                        badgeAlt="Wellstar Medical Group"
                                    />
                                    <div className={styles.cardInfo}>
                                        <div className={styles.summaryHeader}>
                                            <div className={styles.summaryHeaderTitle}>
                                                <div className={styles.cardTitle}>{physician.fullName}</div>
                                                <div className={styles.cardSubTitle}>{physician.primarySpecialty}</div>
                                            </div>
                                        </div>

                                        <div>
                                            {' '}
                                            {typeof physician.ratings === 'number' && physician.ratings > 0 ? (
                                                <div style={{ display: 'flex' }}>
                                                    <div className={styles.rating}>
                                                        <i className="fa fa-star" style={{ color: '#F3C300' }} />
                                                        {physician.ratings.toFixed(1)}
                                                    </div>
                                                    {physician.reviewCount != null && physician.reviewCount > 0 ? (
                                                        <span className={styles.reviewCount}>{physician.reviewCount} Reviews</span>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className={`${styles.textOverflowEllipsis} ${styles.locationName} font-weight-bold`}>{primaryLocation?.name}</div>
                                        <div className={styles.streetAddress}>{primaryLocation?.streetAddress}</div>
                                        <div className={styles.cityStateZip}>{primaryLocation?.cityStateZip}</div>
                                        <div className={styles.fullAddress}>{primaryLocation?.address}</div>
                                        <div className={styles.featureChip}>
                                            {firstChip === undefined ? null : (
                                                <FeatureChipTag
                                                    chip={firstChip}
                                                    summaryCardMode={physician?.allowVirtualVisits || false}
                                                    automationId="PhysicianCard"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.separator}></div>
                            {props.togglePatientScheduling !== true ? null : (
                                <div className={styles.availabilityWrapper}>
                                    <ScheduleSnippet physician={physician} location={location} timeSelectedFunction={selectedSlot} />
                                    {hasAppointments(physician, location) && <button className={styles.viewMoreAvailability} onClick={viewMoreAvailability}>View more availability</button>}
                                </div>
                            )}
                        </div>
                    </div>
                    {allLocations.length <= 1 ? null : (
                        <div className={styles.additionalLocations}>
                            +{allLocations.length - 1} location{allLocations.length > 2 ? 's' : ''}
                        </div>
                    )}
                </>
            )}
        </Context.Consumer>
  );
};
