/* eslint-disable */

import { FeatureChip, MatchingGeo, UnsureType, VisitType } from './common';
import { LocationSchedule } from './schedules';

export class PracticeLocation {
    constructor(location: ILocationSearchResponseLocation | PracticeLocation | null, geo?: MatchingGeo) {
        if (location instanceof PracticeLocation) {
            this.itemId = location.itemId;
            this.name = location.name;
            this.pageURL = location.pageURL;
            this.enableOnlineScheduling = location.enableOnlineScheduling;
            this.isWellStarLocation = location.isWellStarLocation;
            this.address = location.address;
            this.address2 = location.address2;
            this.directions = location.directions;
            this.geomodifier = location.geomodifier;
            this.latitude = location.latitude;
            this.longitude = location.longitude;
            this.openSchedulingDepartmentId = location.openSchedulingDepartmentId;
            this.locationContactPhone = location.locationContactPhone;
            this.locationContactFax = location.locationContactFax;
            this.locationTypes = location.locationTypes.slice(0);
            this.LocationTypesNames = location.LocationTypesNames.slice(0);
            this.workingHours = { ...location.workingHours };
            this.open24Hours = location.open24Hours;
            this.featuredChips = location.featuredChips?.slice(0);
            this.keyDetail = location.keyDetail;
            this.primaryCallToAction = location.primaryCallToAction;
            this.secondaryCallToAction = location.secondaryCallToAction;
            this.associatedPhysicians = location.associatedPhysicians.slice(0);
            this.associatedServices = location.associatedServices.slice(0);
            this.distance = location.distance;
            this.allowVirtualVisits = location.allowVirtualVisits;
            this.featuredImage = location.featuredImage;
            this.schedule = location.schedule;
            this.osVisitTypes = location.osVisitTypes;
            this.epicProviderID = location.epicProviderID;
            this.contactStatement = location.contactStatement;
            this.contactPhoneNumber = location.contactPhoneNumber;
            this.isNeuro = location.isNeuro;
            return;
        }
        if (location === null || typeof location !== 'object') {
            this.itemId = '';
            this.locationTypes = [];
            this.LocationTypesNames = [];
            this.workingHours = null;
            this.open24Hours = false;
            this.featuredChips = [];
            this.keyDetail = null;
            this.primaryCallToAction = null;
            this.secondaryCallToAction = null;
            this.associatedPhysicians = [];
            this.associatedServices = [];
            this.distance = null;
            this.allowVirtualVisits = null;
            this.osVisitTypes = [];
            return;
        }
        this.itemId = location.ItemID;
        this.name = location.Name;
        this.pageURL = location.PageURL;
        this.enableOnlineScheduling = location.EnableOnlineScheduling === true;
        this.isWellStarLocation = location.IsWellStarLocation;
        this.locationTypes = location.LocationTypes ?? [];
        this.LocationTypesNames = location.LocationTypesNames ?? [];
        this.address = location.Address;
        this.address2 = location.Address2;
        this.directions = location.Directions;
        this.geomodifier = location.Geomodifier;
        this.allowVirtualVisits = location.AllowVirtualVisits;
        this.schedule = location.Schedule;
        this.osVisitTypes = location.OSVisitTypes;
        this.epicProviderID = location.EpicProviderID;
        this.contactStatement = location.ContactStatement;
        this.contactPhoneNumber = location.ContactPhoneNumber;
        this.isNeuro = location.IsNeuro;

        let latitude: number | string | null | undefined = location.Latitude,
            longitude: number | string | null | undefined = location.Longitude;
        if (typeof latitude === 'string') latitude = parseFloat(latitude);
        if (typeof longitude === 'string') longitude = parseFloat(longitude);
        if (typeof latitude !== 'number' || isNaN(latitude) || typeof longitude !== 'number' || isNaN(longitude)) {
            latitude = null;
            longitude = null;
        }
        this.latitude = latitude;
        this.longitude = longitude;

        if (location.Hours !== null && typeof location.Hours === 'object' && Object.keys(location.Hours).length > 0) this.workingHours = location.Hours;
        else this.workingHours = null;

        this.open24Hours = location.Open24Hours === true;
        this.openSchedulingDepartmentId = location.OpenSchedulingDepartmentID;
        this.locationContactPhone = location.LocationContactPhone;
        this.locationContactFax = location.LocationContactFax;

        this.featuredChips = (location.FeaturedChips ?? [])
            .filter(x => x !== null && typeof x === 'object')
            .map(chip => {
                return {
                    title: chip?.title,
                    icon:
                        typeof chip?.icon?.imagePath !== 'string' || chip?.icon?.imagePath.length === 0
                            ? null
                            : {
                                  imagePath: chip?.icon.imagePath,
                                  imageAlt: chip?.icon.imageAlt,
                                  imageTitle: chip?.icon.imageTitle,
                              },
                    abstract: chip?.abstract,
                    color: chip?.color,
                };
            });

        this.keyDetail =
            location.KeyDetail !== null &&
            typeof location.KeyDetail === 'object' &&
            typeof location.KeyDetail.Text === 'string' &&
            location.KeyDetail.Text.length > 0 &&
            typeof location.KeyDetail.Url === 'string' &&
            location.KeyDetail.Url.length > 0
                ? {
                      text: location.KeyDetail.Text,
                      url: location.KeyDetail.Url,
                      target: location.KeyDetail.Target,
                      ariaLabel: location.KeyDetail.AriaLabel,
                  }
                : null;

        this.primaryCallToAction =
            location.PrimaryCallToAction !== null &&
            typeof location.PrimaryCallToAction === 'object' &&
            typeof location.PrimaryCallToAction.Text === 'string' &&
            location.PrimaryCallToAction.Text.length > 0 &&
            typeof location.PrimaryCallToAction.Url === 'string' &&
            location.PrimaryCallToAction.Url.length > 0
                ? {
                      text: location.PrimaryCallToAction.Text,
                      url: location.PrimaryCallToAction.Url,
                      target: location.PrimaryCallToAction.Target,
                      ariaLabel: location.PrimaryCallToAction.AriaLabel,
                  }
                : null;
        this.secondaryCallToAction =
            location.SecondaryCallToAction !== null &&
            typeof location.SecondaryCallToAction === 'object' &&
            typeof location.SecondaryCallToAction.Text === 'string' &&
            location.SecondaryCallToAction.Text.length > 0 &&
            typeof location.SecondaryCallToAction.Url === 'string' &&
            location.SecondaryCallToAction.Url.length > 0
                ? {
                      text: location.SecondaryCallToAction.Text,
                      url: location.SecondaryCallToAction.Url,
                      target: location.SecondaryCallToAction.Target,
                      ariaLabel: location.SecondaryCallToAction.AriaLabel,
                  }
                : null;

        this.associatedPhysicians = (location.AssociatedPhysicians ?? [])
            .filter(x => x !== null && typeof x === 'object' && typeof x.Content === 'string' && x.Content.length > 0)
            .map(convertAssociatedItem);

        this.associatedServices = (location.AssociatedServices ?? [])
            .filter(x => x !== null && typeof x === 'object' && typeof x.Content === 'string' && x.Content.length > 0)
            .map(convertAssociatedItem);

        this.distance = geo?.distance ?? null;
        if (location.FeaturedImage?.ImagePath) {
            this.featuredImage = {
                path: location.FeaturedImage.ImagePath,
                title: location.FeaturedImage.ImageTitle,
                alt: location.FeaturedImage.ImageAlt,
                width: location.FeaturedImage.ImageWidth,
                height: location.FeaturedImage.ImageHeight,
            };
        }
    }

    itemId: string;
    name: UnsureType<string>;
    pageURL: UnsureType<string>;
    enableOnlineScheduling: UnsureType<boolean>;
    isWellStarLocation: UnsureType<boolean>;
    locationTypes: string[];
    LocationTypesNames: string[];
    address: UnsureType<string>;
    address2: UnsureType<string>;
    directions: UnsureType<string>;
    latitude: UnsureType<number>;
    longitude: UnsureType<number>;
    geomodifier: UnsureType<string>;
    workingHours: {
        Monday?: string;
        Tuesday?: string;
        Wednesday?: string;
        Thursday?: string;
        Friday?: string;
        Saturday?: string;
        Sunday?: string;
    } | null;
    open24Hours: boolean;
    openSchedulingDepartmentId?: UnsureType<any>;
    locationContactPhone: UnsureType<string>;
    locationContactFax: UnsureType<string>;
    featuredChips?: FeatureChip[];
    allowVirtualVisits?: boolean | null;
    keyDetail: { text: string; url: string; target: UnsureType<string>; ariaLabel: UnsureType<string> } | null;
    primaryCallToAction: { text: string; url: string; target: UnsureType<string>; ariaLabel: UnsureType<string> } | null;
    secondaryCallToAction: { text: string; url: string; target: UnsureType<string>; ariaLabel: UnsureType<string> } | null;
    schedule?: UnsureType<LocationSchedule>;
    epicProviderID?: UnsureType<string>;
    osVisitTypes?: UnsureType<VisitType[]>;
    contactStatement?: UnsureType<string>;
    contactPhoneNumber?: UnsureType<string>;
    associatedPhysicians: {
        name: string;
        pageUrl: string;
        image: {
            imagePath: UnsureType<string>;
            imageAlt: UnsureType<string>;
            imageTitle: UnsureType<string>;
        } | null;
    }[];
    associatedServices: {
        name: string;
        pageUrl: string;
        image: {
            imagePath: UnsureType<string>;
            imageAlt: UnsureType<string>;
            imageTitle: UnsureType<string>;
        } | null;
    }[];

    distance: number | null;
    featuredImage?: {
        path: string;
        alt?: string;
        title?: string;
        width?: string;
        height?: string;
    };
    isNeuro?: UnsureType<boolean>;

    public get streetAddress(): string {
        const parts = this.address?.split(',') ?? [];
        let street = (parts[0] ?? '').trim();
        let street2 = (this.address2 ?? '').trim();

        if (street2.length > 0) street += (street.length > 0 ? ', ' : '') + street2;

        return street;
    }
    public get cityStateZip(): string {
        const parts = this.address?.split(',') ?? [];
        return parts.splice(1).join(',').trim();
    }

    public get isMedicalOffice(): boolean {
        return Array.isArray(this.locationTypes) && this.locationTypes.indexOf('Medical Practice') >= 0;
    }

    public getOpenStatus(currentTimestamp?: number): OpenStatus | null {
        if (!this.hasWorkingHours) return null;

        let currentDate = new Date();
        if (typeof currentTimestamp === 'number') currentDate = new Date(currentTimestamp);

        currentDate = new Date(currentDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);

        const currDay = currentDate.getDay();
        const currHours = currentDate.getHours();
        const currMins = currentDate.getMinutes();

        if (!isNaN(currDay)) {
            const getDay = (num: number): string => {
                switch (num % 7) {
                    case 0:
                        return 'Sunday';
                    case 1:
                        return 'Monday';
                    case 2:
                        return 'Tuesday';
                    case 3:
                        return 'Wednesday';
                    case 4:
                        return 'Thursday';
                    case 5:
                        return 'Friday';
                    default:
                        return 'Saturday';
                }
            };
            let day = getDay(currDay);

            const workingHours = this.workingHoursParsed;
            let dayHours = workingHours.find(x => x.day === day);

            let openStatus: OpenStatus['status'] = 'Open Now';
            let nextTime: string = '';
            if (typeof dayHours?.openHours !== 'number' || typeof dayHours.closeHours !== 'number') openStatus = 'Closed';
            else if (currHours < dayHours.openHours) openStatus = 'Closed';
            else if (currHours === dayHours.openHours) openStatus = currMins < (dayHours.openMinutes ?? 0) ? 'Closed' : 'Open Now';
            else if (currHours === dayHours.closeHours) openStatus = currMins >= (dayHours.closeMinutes ?? 0) ? 'Closed' : 'Open Now';
            else if (currHours > dayHours.closeHours) openStatus = 'Closed';

            if (openStatus === 'Open Now') {
                if (typeof dayHours?.closeHours === 'number') {
                    const d1 = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate(),
                        dayHours.closeHours ?? 0,
                        dayHours.closeMinutes ?? 0,
                        0,
                        0
                    );
                    const timeDiff = Math.round((d1.getTime() - currentDate.getTime()) / 1000) / 60;
                    if (timeDiff <= 30) openStatus = 'Closing Soon';

                    nextTime = 'Closes at ' + PracticeLocation.formatTime(dayHours.closeHours, dayHours.closeMinutes);
                }
            } else if (
                dayHours !== undefined &&
                typeof dayHours.openHours === 'number' &&
                (currHours < dayHours.openHours || (currHours === dayHours.openHours && currMins < (dayHours.openMinutes ?? 0)))
            ) {
                const d1 = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate(),
                    dayHours?.openHours ?? 0,
                    dayHours?.openMinutes ?? 0,
                    0,
                    0
                );
                const timeDiff = Math.round((d1.getTime() - currentDate.getTime()) / 1000) / 60;
                if (timeDiff <= 30) openStatus = 'Opening Soon';

                nextTime = 'Opens at ' + PracticeLocation.formatTime(dayHours.openHours, dayHours.openMinutes);
            } else {
                dayHours = undefined;
                for (let d = 1; d < 7; d++) {
                    let nday = getDay(currDay + d);
                    dayHours = workingHours.find(x => x.day === nday && typeof x.openHours === 'number');
                    if (dayHours !== undefined) break;
                }
                if (dayHours !== undefined) nextTime = `Opens ${dayHours.day} at ${PracticeLocation.formatTime(dayHours.openHours, dayHours.openMinutes)}`;
            }

            return {
                status: openStatus,
                nextTime,
            };
        }

        return null;
    }
    public get hasWorkingHours(): boolean {
        return (
            (this.workingHours == null
                ? false
                : this.workingHours.Monday !== null ||
                  this.workingHours.Tuesday !== null ||
                  this.workingHours.Wednesday !== null ||
                  this.workingHours.Thursday !== null ||
                  this.workingHours.Friday !== null ||
                  this.workingHours.Saturday !== null ||
                  this.workingHours.Sunday !== null) && typeof this.workingHours === 'object'
        );
    }
    public get workingHoursParsed(): { day: string; openHours?: number; openMinutes?: number; closeHours?: number; closeMinutes?: number }[] {
        if (!this.hasWorkingHours) return [];

        const workingHours = this.workingHours ?? {};
        const days: (keyof typeof workingHours)[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        return (
            days?.map(day => {
                let dayHours = workingHours[day];
                const hours = dayHours?.split('-') ?? [];
                if (hours.length !== 2) return { day };

                let opens = hours[0].split(':').map(x => parseInt(x));
                if (opens.filter(x => !isNaN(x)).length !== 2) opens = [];
                let closes = hours[1].split(':').map(x => parseInt(x));
                if (closes.filter(x => !isNaN(x)).length !== 2) closes = [];
                return { day, openHours: opens[0], openMinutes: opens[1], closeHours: closes[0], closeMinutes: closes[1] };
            }) ?? []
        );
    }
    public static formatWorkingHours(
        time: { day: string; openHours?: number; openMinutes?: number; closeHours?: number; closeMinutes?: number },
        alwaysIncludeMinutes: boolean = true
    ): string[] {
        if (time === null || typeof time !== 'object' || typeof time.day !== 'string' || time.day.length === 0) return [];

        if (typeof time.openHours !== 'number' && typeof time.closeHours !== 'number') return [time.day, 'Closed'];

        const opens = this.formatTime(time.openHours, time.openMinutes, alwaysIncludeMinutes);
        const closes = this.formatTime(time.closeHours, time.closeMinutes, alwaysIncludeMinutes);

        return [time.day, `${opens} - ${closes}`];
    }
    public static formatTime(hours: number | undefined, minutes: number | undefined, alwaysIncludeMinutes: boolean = true): string {
        if (typeof hours !== 'number') return '';
        if (typeof minutes !== 'number') minutes = 0;

        let result = '';
        let ampm: 'AM' | 'PM' = 'AM';
        if (hours === 0) hours = 12;
        else if (hours === 12) ampm = 'PM';
        else if (hours > 12 && hours <= 23) {
            ampm = 'PM';
            hours -= 12;
        } else if (hours > 23) return '';

        result = hours.toString();
        if (alwaysIncludeMinutes === true || minutes > 0) result += ':' + ('0' + minutes.toString()).substr(Math.max(0, minutes.toString().length - 1));

        result += ' ' + ampm;

        return result;
    }
}

const convertAssociatedItem = (item: AssociatedItem): PracticeLocation['associatedPhysicians'][0] => {
    return {
        name: item.Content ?? '',
        pageUrl: item.PageUrl ?? '',
        image:
            typeof item.Image?.ImagePath !== 'string' || item.Image.ImagePath.length === 0
                ? null
                : {
                      imagePath: item.Image.ImagePath,
                      imageAlt: item.Image.ImageAlt,
                      imageTitle: item.Image.ImageTitle,
                  },
    };
};

export type OpenStatus = {
    status: 'Open Now' | 'Opening Soon' | 'Closing Soon' | 'Closed';
    nextTime: string;
};

export interface ILocationSearchResponse {
    matchingItems: ILocationSearchResponseLocation[];
    matchingGeo?: MatchingGeo[];
    serverTime?: number;
    TogglePatientScheduling?: boolean;
}
export interface ILocationSearchResponseLocation {
    Address?: UnsureType<string>;
    Address2?: UnsureType<string>;
    ContactStatement?: UnsureType<string>;
    ContactPhoneNumber?: UnsureType<string>;
    Directions?: UnsureType<string>;
    EpicProviderID?: UnsureType<string>;
    FeaturedChips?: UnsureType<FeatureChip>[];
    Hours?: UnsureType<{
        Monday?: string;
        Tuesday?: string;
        Wednesday?: string;
        Thursday?: string;
        Friday?: string;
        Saturday?: string;
        Sunday?: string;
    }>;
    IsWellStarLocation?: UnsureType<boolean>;
    ItemID: string;
    KeyDetail?: UnsureType<{ Text: UnsureType<string>; Url: UnsureType<string>; Target: UnsureType<string>; AriaLabel: UnsureType<string> }>;
    Latitude?: UnsureType<number>;
    ListOfPhysician?: {
        NPID: string;
        AllowVirtualVisits: boolean;
        FirstName: string;
        LastName: string;
        Profession: string[];
        PageUrl: string;
    }[];
    LocationContactFax?: UnsureType<string>;
    LocationContactPhone?: UnsureType<string>;
    LocationTypes?: UnsureType<string[]>;
    LocationTypesNames?: UnsureType<string[]>;
    Longitude?: UnsureType<number>;
    Name?: UnsureType<string>;
    Open24Hours?: UnsureType<boolean>;
    OpenSchedulingDepartmentID?: UnsureType<any>;
    OSVisitTypes?: UnsureType<VisitType[]>;
    PageURL?: UnsureType<string>;
    EnableOnlineScheduling?: UnsureType<boolean>;
    PrimaryCallToAction?: { Text: UnsureType<string>; Url: UnsureType<string>; Target: UnsureType<string>; AriaLabel: UnsureType<string> };
    SecondaryCallToAction?: { Text: UnsureType<string>; Url: UnsureType<string>; Target: UnsureType<string>; AriaLabel: UnsureType<string> };
    AssociatedPhysicians?: UnsureType<AssociatedItem[]>;
    AssociatedServices?: UnsureType<AssociatedItem[]>;
    Geomodifier?: UnsureType<string>;
    AllowVirtualVisits?: UnsureType<boolean>;
    Schedule?: UnsureType<LocationSchedule>;
    FeaturedImage?: {
        ImagePath?: string;
        ImageAlt?: string;
        ImageTitle?: string;
        ImageWidth?: string;
        ImageHeight?: string;
    };
    IsNeuro?: UnsureType<boolean>;
}
interface AssociatedItem {
    Content: UnsureType<string>;
    PageUrl: UnsureType<string>;
    Image: UnsureType<{
        ImagePath: UnsureType<string>;
        ImageAlt: UnsureType<string>;
        ImageTitle: UnsureType<string>;
    }>;
}
