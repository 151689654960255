/* eslint-disable */

import { addMonths, format } from 'date-fns';
import { UnsureType } from './common';
import { PracticeLocation } from './locations';
import { Physician } from './physicians';

export class SchedulingInput {
    constructor(entry: string, data: any) {
        this.entry = entry;
        this.data = data;
    }
    entry: string = 'physician';
    data: any | undefined = undefined;
}

export interface ILocationScheduleItem {
    provider: UnsureType<Provider>;
    sas: UnsureType<SASScore>[];
    departments: UnsureType<Department>[];
    scheduleDays: UnsureType<ScheduleDay>[];
    visitTypes: UnsureType<VisitType>[];
}
class Provider {
    displayName: string = '';
    iDs: IDType[] = [];
}
export class LocationSchedule {
    constructor(item?: ILocationScheduleItem) {
        if (item === null || typeof item !== 'object') return;
        this.departments = item.departments as Department[];
        this.scheduleDays = item.scheduleDays.map(x => new ScheduleDay(x as IScheduleDay));
        this.visitTypes = item.visitTypes.map(x => new VisitType(x as IVisitTypeItem));
        this.provider = item.provider as Provider;
        this.sas = item.sas as SASScore[];
    }
    departments: Department[] = [];
    provider: Provider | null = new Provider();
    sas: SASScore[] = [];
    scheduleDays: ScheduleDay[] = [];
    visitTypes: VisitType[] = [];
}
class SASScore {
    id: string = '';
    weighted: number = 0;
}
class CategoryValue {
    number?: string | null = '';
    title?: string | null = '';
    abbreviation?: string | null = '';
}
class Address {
    streetAddress: (string | null)[] = [];
    city: string = '';
    postalCode: string = '';
    houseNumber?: string | null = '';
    latitude?: number | null;
    longitude?: number | null;
    state?: CategoryValue = new CategoryValue();
}
export class Department {
    name: string = '';
    address?: Address = new Address();
    iDs?: IDType[] = new Array<IDType>();
    phones?: Phone[] = new Array<Phone>();
    providers?:
        | {
              name: string;
              pageUrl: string;
              image: {
                  imagePath: UnsureType<string>;
                  imageAlt: UnsureType<string>;
                  imageTitle: UnsureType<string>;
              } | null;
          }[]
        | null;
    active?: boolean = false;
    disabled?: boolean = false;
    directions?: string | null = '';
}
interface IVisitTypeItem {
    name: UnsureType<string>;
    displayName: UnsureType<string>;
    iDs: UnsureType<IDType>[];
}
class VisitType {
    constructor(item: IVisitTypeItem) {
        this.name = item.name as string;
        this.displayName = item.displayName as string;
        this.iDs = item.iDs as IDType[];
    }
    name: string = '';
    displayName: string = '';
    iDs: IDType[] = [];
}

class Phone {
    number: string = '';
    type: string = '';
}
class IDType {
    id: string = '';
    type: string = '';
}

interface IScheduleDay {
    date: UnsureType<string>;
    slotBlockType?: UnsureType<string>;
    provider?: UnsureType<IDType>;
    department?: UnsureType<IDType>;
    visitType: UnsureType<IDType>[] | UnsureType<IDType>;
    slots: UnsureType<string>[];
    arrivalTime: UnsureType<number>;
    timeSlots: UnsureType<string>[];
}
class ScheduleDay {
    constructor(item: IScheduleDay) {
        this.date = item.date as string;
        this.slotBlockType = item.slotBlockType as string;
        this.provider = item.provider as IDType;
        this.department = item.department as IDType;
        this.slots = item.slots as string[] | null;
        this.visitType = item.visitType as IDType[];
        this.timeSlots = item.timeSlots as string[];
        this.arrivalTime = item.arrivalTime as number;
    }
    date: string = '';
    slotBlockType?: string = '';
    provider?: IDType | null;
    department?: IDType | null;
    visitType?: IDType[] | IDType;
    arrivalTime: number = 15;
    slots?: string[] | null = [];
    timeSlots: string[] = [];
}
export class Timeslot {
    hour!: number;
    hourText!: string;
    arriveByText: string = '';
    slotBlockType: string = '';
    isDisabled: boolean = false;
    isActive: boolean = false;
    provider?: Physician;
}
export class AllSlots {
    daySlots: DaySlots[] = [];
}
export class DaySlots {
    constructor(date: string) {
        this.date = date;
    }
    date: string = '';
    departmentId: string = '';
    slots: Timeslot[] = [];
    npid?: string;
}
export class WeekDay {
    day!: number;
    dayText!: string;
}

export const setTimeSlots = (locationSchedules: LocationSchedule) => {
    //load slots into map
    if (!locationSchedules) return;
    return locationSchedules.scheduleDays?.map(x => {
        return {
            date: format(new Date(x.date as string), 'yyyy-MM-dd'),
            departmentId: x.department?.id,
            slots: x.timeSlots?.map((y: string) => {
                return {
                    hour: convertStringTime(y),
                    hourText: formatStringTime(y),
                    arriveByText: formatStringArrivalTime(y, x.arrivalTime),
                    slotBlockType: x.slotBlockType,
                    isDisabled: false,
                    isActive: false,
                } as Timeslot;
            }),
        } as DaySlots;
    });
};
const formatStringArrivalTime = (time: string, minutes: number): string => {
    const hrs = formatStringTime(time).split(':');
    if (hrs.length > 1) {
        const mins = hrs[1].split(' ');
        const newMinutes = +mins[0] - minutes;
        if (newMinutes < 0) {
            if (+hrs[0] - 1 == 0) {
                if (mins[1] == 'AM') {
                    return `12:${60 + newMinutes} PM`;
                } else {
                    return `12:${60 + newMinutes} AM`;
                }
            } else {
                return `${+hrs[0] - 1}:${60 + newMinutes} ${mins[1]}`;
            }
        } else {
            return `${+hrs[0]}:${newMinutes ? newMinutes : '00'} ${mins[1]}`;
        }
    }
    return '';
};
const convertStringTime = (time: string): number => {
    const timeParts = time?.split(':');
    if (timeParts.length > 1) {
        return +timeParts[0];
    }
    return 0;
};
const formatStringTime = (time: string): string => {
    const timeParts = time?.split(':');
    if (timeParts.length > 1) {
        if (+timeParts[0] > 12) {
            return `${+timeParts[0] - 12}:${timeParts[1]} PM`;
        } else if (+timeParts[0] == 12) return `${+timeParts[0]}:${timeParts[1]} PM`;
        else {
            return `${timeParts[0]}:${timeParts[1]} AM`;
        }
    }
    return '';
};

const getDatesBetween = (startDate: Date, endDate: Date, includeEndDate?: boolean) => {
    const dates = [] as Date[];
    const currentDate = startDate;
    while (currentDate < endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    if (includeEndDate) dates.push(endDate);
    return dates;
};

// export const getTimeSlotsByDatePeriod = (startDate: Date, endDate: Date): DaySlots[] => {
//     const dateArray = getDatesBetween(startDate, endDate, true);
//     let allDaySlots = [];
//     for (let i = 0; i < dateArray.length; i++) {
//         const currentDateString = format(dateArray[i], 'yyyy-MM-dd');
//         allDaySlots.push(
//             appointmentData.allTimeSlots.find(x => x.date == currentDateString && x.departmentId == currentSelectedLocationId.value && x.slots.length > 0)
//         );
//     }
//     return allDaySlots.filter(Boolean);
// };

export const getAllTimeSlots = (currentSelectedLocationIds: IDType[] | undefined, allTimeSlots: DaySlots[] | undefined) => {
    let allDaySlots = [] as DaySlots[];
    if (allTimeSlots !== undefined && currentSelectedLocationIds !== undefined) {
        allTimeSlots.forEach(x => {
            if (currentSelectedLocationIds.filter(y => y.id === x.departmentId).length > 0 && x.slots !== undefined && x.slots.length > 0) {
                // If the whole day hasn't been added yet, can just add every slot
                const dayEntry = allDaySlots.find(y => y.date === x.date);
                if (!dayEntry) {
                    allDaySlots.push(x);
                } else {
                    x.slots.forEach(y => {
                        //check if the slot already exist
                        if (!(dayEntry.slots.filter(z => z.hourText === y.hourText).length > 0)) {
                            // Merge in the additional slots and keep them sorted by time
                            dayEntry.slots = dayEntry.slots.concat(y);
                            dayEntry.slots.sort((a, b) => a.hour - b.hour || a.hourText.localeCompare(b.hourText));
                        }
                    });
                }
            }
        });
    }
    let allSlots = new AllSlots();
    allDaySlots.filter((v, i, a) => a.findIndex(v2 => v2.date === v.date) === i);
    allSlots.daySlots = allDaySlots;
    return allSlots;
};

export const isSameDepartmentLocation = (depart1: Department, depart2: Department) => {
    return depart1.iDs
        ?.filter(id => id.type === 'External')
        .map(idobject => idobject.id)
        .some(id =>
            depart2.iDs
                ?.filter(id => id.type === 'External')
                .map(idobject => idobject.id)
                .includes(id)
        );
};
export const departmentIsInList = (department1: Department, departmentList: Department[]) => {
    let isInList = false;
    for (var i = 0; i < departmentList.length; i++) {
        if (isSameDepartmentLocation(department1, departmentList[i])) {
            isInList = true;
        }
    }
    return isInList;
};
export const combineLocationSchedules = (schedules: Array<LocationSchedule> | undefined, location: Department) => {
    if (!schedules) return;
    let schedule = schedules[0];

    // Ideally this should be done on the backend or in a centralized data transformation layer
    const validLocationDeptIds = location.iDs?.map(dept => dept.id) || [];

    for (var i = 1; i < schedules.length; i++) {
        schedule.scheduleDays.filter(day => validLocationDeptIds.includes(day.department?.id || ''));
        schedule.scheduleDays = schedule?.scheduleDays.concat(schedules[i].scheduleDays);
        // deduplicate
        schedule.scheduleDays.filter((v, i, a) => a.findIndex(v2 => v2.date === v.date) === i);
        schedule.visitTypes = schedule?.visitTypes.concat(schedules[i].visitTypes);
    }
    return schedule;
};
export const matchLocationToDepartment = (location: PracticeLocation, departmentList: Department[]) => {
    let department1 = locationToDepartment(location);
    for (var i = 0; i < departmentList.length; i++) {
        if (isSameDepartmentLocation(department1, departmentList[i])) {
            let address = departmentList[i]?.address;
            if (address) {
                address.latitude = department1?.address?.latitude;
                address.longitude = department1?.address?.longitude;
            }
            return department1;
        }
    }
    return null;
};
export const locationToDepartment = (location: PracticeLocation) => {
    if (location !== undefined) {
        let department = new Department();
        if (location.address != undefined) {
            department.address = new Address();
            department.address.state = new CategoryValue();
            department.address.longitude = location.longitude;
            department.address.latitude = location.latitude;
            let newAddress = location.address?.length > 1 ? location.address?.split(',') : null;
            let newAddress2 = location.address?.length > 1 ? location.address2 : null;
            if (newAddress != null && newAddress.length >= 4) {
                department.address?.streetAddress.push(newAddress[0].trim());
                department.address.postalCode = newAddress[3].trim();
                department.address.houseNumber = '';
                department.address.state.abbreviation = newAddress[2].trim();
                department.address.state.title = '';
                department.address.state.number = '';
                department.address.city = newAddress[1].trim();
            }
            if (newAddress2 != null) department.address?.streetAddress.push(newAddress2.trim());
        }
        department.iDs = Array.from(new Set(location?.openSchedulingDepartmentId?.split(','))).map(x => {
            return {
                id: x as string,
                type: 'External',
            };
        });
        department.phones = [
            {
                number: location.locationContactPhone ? location.locationContactPhone : '',
                type: 'External',
            },
        ];
        department.providers = location.associatedPhysicians;
        department.name = location.name ? location.name : '';
        department.disabled = true;
        department.directions = location.directions;
        return department;
    } else {
        return new Department();
    }
};

export const hasAppointments = (physician: Physician, location: PracticeLocation) => {
    if (location.openSchedulingDepartmentId === null) return false;
    var currentLocationIds = [] as any[];
    if (location.openSchedulingDepartmentId?.includes(',')) {
        currentLocationIds = Array.from(new Set(location.openSchedulingDepartmentId?.split(','))).map(x => {
            return {
                id: x as string,
                type: 'External',
            };
        });
    } else {
        currentLocationIds = [
            {
                id: location.openSchedulingDepartmentId,
                type: 'External',
            },
        ];
    }
    if (
        physician.schedule?.scheduleDays?.find(x => {
            if (
                currentLocationIds?.filter(y => y.id === x.department?.id && x.department?.type === 'External') !== undefined &&
                currentLocationIds?.filter(y => y.id === x.department?.id && x.department?.type === 'External').length > 0
            ) {
                return x;
            } else {
                return undefined;
            }
        }) !== undefined
    )
        return true;
    else return false;
};

export const getPhysicianSchedule = async (npid: string, startDate?: Date, endDate?: Date) => {
    const start = startDate ?? new Date();
    const end = endDate ?? addMonths(start, 3);

    const apiUrl = `/api/scheduling/providers/schedules?providerId=${npid}&startDate=${format(start, 'yyyy-MM-dd')}&endDate=${format(end, 'yyyy-MM-dd')}`;

    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
    });

    if (!response.ok) {
        console.log(response, 'error trying to retrieve physician schedule');
        return null;
    }
    const schedule: LocationSchedule = await response.json();
    schedule?.departments?.forEach(x => {
        x.active = false;
        x.disabled = false;
    });

    return schedule;
};
