import React, { useEffect, useState } from 'react';
import './clinic-visit-type-toggle.scss';

interface IClinicVisitTypeToggle {
  hasVirtualVisitToggle: boolean;
  onToggle: Function;
}

function ClinicVisitTypeToggle(props: IClinicVisitTypeToggle) {
  const { hasVirtualVisitToggle, onToggle } = props;
  const [isVVSelected, setVVSelected] = useState(false);

  useEffect(() => {
    onToggle(isVVSelected);
  }, [isVVSelected]);

  return (
    <div className="clinic-visit-type-toggle">
      <button
        type="button"
        className={isVVSelected ? '' : 'selected'}
        data-id="clinic-visit-toggle-in-person"
        onClick={() => setVVSelected(false)}
      >
        In-Person
      </button>
      {hasVirtualVisitToggle && (
        <button
          type="button"
          className={isVVSelected ? 'selected' : ''}
          data-id="clinic-visit-toggle-virtual-video"
          onClick={() => setVVSelected(true)}
        >
          Virtual Video
        </button>
      )}
    </div>
  );
}

export default ClinicVisitTypeToggle;
