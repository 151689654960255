import React from 'react';
import './Ksuiframe.scss';

function KsuIframePage() {
  const iframeSrc = `${window.location.origin}/ksu-widget`;
  return (
    <div className="container">
      <iframe title="iframe for KSU" className="ksu-iframe" src={iframeSrc} />
    </div>
  );
}

export default KsuIframePage;
