import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import getPhysicianResultsItems from './physician-results';
import PractitionersList from './practitioners-list';
import PractitionersMap from './practitioners-map';
import { PhysicianResult } from '../../types/physicians';
import { PracticeLocation } from '../../types/locations';
import physicianSearch from '../../api/physician-search-proxy';

export default function PractitionersContainer() {
  const [physicians, setPhysicians] = useState<PhysicianResult[]>();
  const [clickedLocation, setClickedLocation] = useState<PracticeLocation>();

  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-api-load-script',
    googleMapsApiKey: 'AIzaSyBkGDW0XV7j8JcEHH-2RW9lGsM9HtWZmOA',
  });

  const fetchData = useCallback(async () => {
    const response = await physicianSearch();
    let list:PhysicianResult[] = getPhysicianResultsItems(response);
    list = list.filter((result) => result.physician.enableOnlineScheduling);
    setPhysicians(list);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const practiceLocations = useMemo(() => physicians?.map((physician) => physician.matchedLocations).flat(), [physicians]);

  return (
    <div className="nearby-practitioners-container">
      {physicians && practiceLocations && (
        <>
          <PractitionersList
            physicians={physicians}
            scrollToLocation={clickedLocation}
            doneScrolling={() => setClickedLocation(undefined)}
          />
          {isLoaded && (
            <PractitionersMap
              data={practiceLocations}
              maxZoomLevel={17}
              onMarkerClick={((location: PracticeLocation) => {
                setClickedLocation(location);
              })}
            />
          )}
        </>
      )}
    </div>
  );
}
