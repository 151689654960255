/* eslint-disable */ 

import { ILocationApiLocationDetailsResponse, ILocationApiLocationDetailsResponseItem, IProfileLocation } from '../types/profile-locations';
import { client } from './client';

export const formatLocationDetailsResponse = (data: ILocationApiLocationDetailsResponseItem): IProfileLocation => {
    return {
        isVirtualVisit: false,
        Address: data.Address,
        Address2: data.Address2,
        ContactStatement: data.ContactStatement,
        ContactPhoneNumber: data.ContactPhoneNumber,
        Directions: data.Directions,
        EpicProviderID: data.EpicProviderID,
        ItemID: data.ItemID.Guid,
        EnableOnlineScheduling: data.EnableOnlineScheduling,
        FeaturedChips: data.FeaturedChips,
        Hours: data.WorkingHours,
        IsWellStarLocation: data.IsWellStarLocation,
        KeyDetail: {
            Text: data.PrimaryCTAText,
            Url: data.PrimaryCTAURL,
            Target: null,
            AriaLabel: data.PrimaryCTAText,
        },
        Latitude: parseFloat(data.LocationLatitude ?? ''),
        ListOfPhysician: data.ListOfPhysician,
        LocationContactFax: data?.ContactNumbers?.Fax,
        LocationContactPhone: data?.ContactNumbers?.Phone,
        LocationTypes: null,
        LocationTypesNames: data.LocationTypesNames,
        Longitude: parseFloat(data.LocationLongitude ?? ''),
        Name: data.LocationName,
        Open24Hours: data.Open24Hours,
        OpenSchedulingDepartmentID: data.OpenSchedulingDepartmentID,
        OSVisitTypes: data.OSVisitTypes,
        PageURL: data.PageURL,
        PrimaryCallToAction: {
            Text: data.PrimaryCTAText,
            Url: data.PrimaryCTAURL,
            Target: null,
            AriaLabel: data.PrimaryCTAText,
        },
        SecondaryCallToAction: {
            Text: data.SecondaryCTAText,
            Url: data.SecondaryCTAURL,
            Target: null,
            AriaLabel: data.SecondaryCTAText,
        },
        AssociatedPhysicians: null,
        AssociatedServices: null,
        Geomodifier: null,
    };
};

export const formatLocationDetailsResponseList = (response: ILocationApiLocationDetailsResponse) => {
    return response.LocationDetails.map(responseItem => formatLocationDetailsResponse(responseItem));
};

export const getLocationDetails = (locationId: string) => {
    return client.get(`/api/LocationDetailApi/GetLocationDetails?locationId=${locationId}`).then(formatLocationDetailsResponse);
};
