import React from 'react';
import SectionTitle from '../section-title';
import SectionParagraph from '../section-paragraph';
import SectionParagraphWithPhone from '../section-paragraph-with-phone';
import BackToTop from '../back-to-top';
import Clinic from '../clinic';
import getEnvVariable from '../../utils/env';
import { studentHealthText } from './shared';
import './clinic-locations.scss';

function ClinicLocations() {
  const serializedLocations = getEnvVariable('REACT_APP_KSU_Clinics');
  let locationIds = serializedLocations.split(';');
  locationIds = locationIds.filter((id) => id.trim() !== '');

  return (
    <section className="clinic-locations container" id="clinic-locations">
      <SectionTitle title="KSU Clinic Locations" />
      <SectionParagraph paragraph={studentHealthText} />
      <SectionParagraphWithPhone />
      <div className="row clinic-container-parent">
        {locationIds.map((id) => (
          <div className="clinic-location col-lg-4 col-sm-12" key={id}>
            <Clinic locationId={id} />
          </div>
        ))}
      </div>
      <BackToTop />
    </section>
  );
}

export default ClinicLocations;
