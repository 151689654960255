import React from 'react';
import banner from './healthClinicBanner.jpg';
import './hero.scss';

function Hero() {
  return (
    <section id="KSU-Hero" className="ksu-hero">
      <img src={banner} className="img-fluid" alt="Phsyican examining student" />
      <div className="student-health-container">
        <div className="container">
          <div className="text-container">
            <h1>Student Health Services</h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
