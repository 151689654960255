import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';
import './cookie-preferences.scss';

function CookiePreferences() {
  const [showAcknowledged, setShowAcknowledged] = useState(false);

  useEffect(() => {
    const consentAcknowledged = localStorage.getItem('wellstarConsentAcknowledged');
    if (consentAcknowledged !== 'true') {
      setShowAcknowledged(true);
    }
  }, []);

  // Array of callbacks to be executed when consent changes
  const consentListeners = [];

  /**
   * Called from GTM template to set callback to be executed when user consent is provided.
   * @param {function} Callback to execute on user consent
   */
  window.addWellstarConsentListener = (callback) => {
    consentListeners.push(callback);
  };

  /**
   * Called when user grants/denies consent.
   * @param {Object} Object containing user consent settings.
   */
  const onConsentChange = (consent) => {
    consentListeners.forEach((callback) => {
      callback(consent);
    });
  };

  const closeBanner = () => {
    localStorage.setItem('wellstarConsentAcknowledged', 'true');
    setShowAcknowledged(false);
  };

  const rejectAllCookies = () => {
    const consentObject = {
      adConsentGranted: false, analyticsConsentGranted: false, functionalityConsentGranted: false, personalizationConsentGranted: false, securityConsentGranted: false,
    };
    const stringConsent = JSON.stringify(consentObject);
    Cookies.set('wellstar_cookie_consent', stringConsent);
    localStorage.setItem('wellstarConsentAcknowledged', 'true');
    setShowAcknowledged(false);
    onConsentChange(consentObject);
  };

  const acceptAllCookies = () => {
    const consentObject = {
      adConsentGranted: true, analyticsConsentGranted: true, functionalityConsentGranted: true, personalizationConsentGranted: true, securityConsentGranted: true,
    };
    const stringConsent = JSON.stringify(consentObject);
    Cookies.set('wellstar_cookie_consent', stringConsent);
    localStorage.setItem('wellstarConsentAcknowledged', true);
    setShowAcknowledged(false);
    onConsentChange(consentObject);
  };

  return (
    <section
      className={`
      cookie-preferences 
      row
      ${showAcknowledged === true ? 'd-flex' : 'd-none'}
      `}
      id="cookie-preferences"
    >
      <div className="col-12">
        <div id="cookie-consent-banner" className="cookie-consent-banner">
          <div className="cookie-content-container">
            <span id="cookie-title" className="cookie-title">
              Cookie Preferences
            </span>
            <p>
              We use cookies for booking and general analytics. Learn more about our &nbsp;
              <a href="https://www.wellstar.org/financial-policy-and-privacy-info/internet-privacy-policy" target="_blank" rel="noreferrer">internet privacy policy</a>
              .
            </p>
            <button
              onClick={closeBanner}
              type="button"
              id="cookie-consent-banner-close-mobile"
              className="close-consent-banner-mobile btn-close"
              aria-label="cookie_consent_banner_close"
            />
          </div>
          <div className="cookie-btn-container">
            <button
              onClick={rejectAllCookies}
              type="button"
              id="cookie-deny-btn"
              className="consent-btn"
              aria-label="deny_cookies"
            >
              Deny all but necessary
            </button>
            <button
              onClick={acceptAllCookies}
              type="button"
              id="cookie-accept-btn"
              className="consent-btn"
              aria-label="accept_cookies"
            >
              Accept all cookies
            </button>
            <button
              onClick={closeBanner}
              type="button"
              id="cookie-consent-banner-close-desktop"
              className="close-consent-banner-desktop btn-close"
              aria-label="cookie_consent_banner_close"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CookiePreferences;
