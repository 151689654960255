/* eslint-disable */
import React, { FunctionComponent, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { PracticeLocation } from '../../types/locations';
import styles from './LocationDetails.module.scss';

interface ILocationDetailsProps {
    location: PracticeLocation;
    showLocationName?: boolean;
    serverTime?: number;
    onHoursExpanded?: (state: boolean) => void;
    ids?: { phoneNumber: string };
    isVirtualVisitSelected?: boolean;
    isSnippet?: boolean;
    indexNumber?: number;
}
export const LocationDetails: FunctionComponent<ILocationDetailsProps> = props => {
    const { location, showLocationName, serverTime, onHoursExpanded, isVirtualVisitSelected, ids, isSnippet, indexNumber } = props;
    const [primaryLocationExpanded, setPrimaryLocationExpanded] = useState(true);
    const [hoursExpanded, setHoursExpanded] = useState(false);

    if (location === null || typeof location !== 'object') return null;

    const workingHours = location.workingHoursParsed;
    let currStatus: string = 'Hours of Operation';
    const openStatus = location.getOpenStatus(serverTime);
    if (openStatus) {
        currStatus = openStatus.status;
    }

    const getStatusElem = (status: string, nextTime?: string) => {
        const emStatus = status === 'Open Now' ? <em>{status}</em> : <>{status}</>;
        return nextTime ? (
            <>
                {emStatus} • {nextTime}
            </>
        ) : (
            <>{emStatus}</>
        );
    };

    let { open24Hours, hasWorkingHours } = location;
    hasWorkingHours = hasWorkingHours && !open24Hours;

    const toggleHours = (expanded: boolean) => {
        setHoursExpanded(expanded);
        if (typeof onHoursExpanded === 'function') window.setTimeout(() => onHoursExpanded(expanded), 0);
    };

    return (
        <div id={`location-card-time-${indexNumber}`} className={styles.locationInfo}>
          { !isSnippet ? 
          <div className={styles.primaryLocationContainer}>
                {open24Hours || workingHours.length > 0 ? (
                    <div
                        className={`${styles.locationItem} ${hasWorkingHours ? styles.locationHours : ''}`}
                        data-id="location-hours-toggle"
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => hasWorkingHours && toggleHours(!hoursExpanded)}
                        onKeyDown={e => hasWorkingHours && (e.key === 'Enter' || e.key === ' ') && toggleHours(!hoursExpanded)}
                        tabIndex={hasWorkingHours ? 0 : -1}
                    >
                        <i className={styles.leftIcon + ' fa fa-clock'}></i>
                        <div>{open24Hours ? 'Open 24 hours a day, seven days a week' : getStatusElem(currStatus, openStatus?.nextTime)}</div>
                        {hasWorkingHours ? <i className={' fa ' + (hoursExpanded ? 'fa-chevron-up' : 'fa-chevron-down')}></i> : null}
                    </div>
                ) : null}
                <CSSTransition in={hoursExpanded} timeout={parseInt(styles.hours_transition_duration)}>
                    <div className={styles.locationHoursDetail}>
                        {location.workingHoursParsed.map((x, idx) => {
                            const [day, hours] = PracticeLocation.formatWorkingHours(x, true);
                            const isToday = day === new Date().toLocaleString([], { weekday: 'long' });
                            return (
                                <dl key={idx} className={`${styles.hoursContainer} ${isToday ? styles.today : ''}`}>
                                    <dt data-id={`hours-day-of-week-${day}`} className={styles.day}>
                                        {day}:
                                    </dt>
                                    <dd data-id={`hours-working-hours-${day}`} className={styles.hours}>
                                        {hours}
                                    </dd>
                                </dl>
                            );
                        })}
                    </div>
                </CSSTransition>
                {typeof location.locationContactPhone !== 'string' || location.locationContactPhone.length === 0 ? null :(
                    <div className={`${styles.locationItem} ${styles.locationPhone}`}>
                        <i className={styles.leftIcon + ' fa fa-phone-alt'}></i>
                        <div>
                            <a data-id="location-details-phone-number" href={'tel:+1' + location.locationContactPhone}>
                                {location.locationContactPhone}
                            </a> 
                        </div>
                    </div>
                )}
            </div>
            :
            <div className={styles.primaryLocationContainer}>
                {open24Hours || workingHours.length > 0 && (
                    <div
                        className={`${styles.locationItem} ${hasWorkingHours ? styles.locationHoursSnippet : ''}`}
                        data-id="location-hours-toggle"
                        tabIndex={hasWorkingHours ? 0 : -1}
                    >
                        <div>{open24Hours ? 'Open 24 hours a day, seven days a week' : getStatusElem(currStatus, openStatus?.nextTime)}</div>
                    </div>
                )}
              </div>
              }
          </div>
    );
};
