/* eslint-disable arrow-body-style */
import { PracticeLocation } from '../types/locations';

export const areLatLngEqual = (latlng1: google.maps.LatLng | undefined, latlng2: google.maps.LatLng | undefined) => {
  return latlng1?.lat() === latlng2?.lat() && latlng1?.lng() === latlng2?.lng();
};

export const getDataBounds = (data: PracticeLocation[]) => {
  const bounds = new google.maps.LatLngBounds();
  data.forEach((location: PracticeLocation) => {
    bounds.extend(new google.maps.LatLng(location.latitude ?? 0, location.longitude));
  });
  return bounds;
};
