import React, { useState, useEffect } from 'react';
import './KsuSnippet.scss';
import KSLogo from './KsuLogoDark.png';
import WSLogo from './wellstar-logo.svg';
import SectionParagraph from '../section-paragraph';
import { ksuHealthText } from './shared';
import getEnvVariable from '../../utils/env';
import ClinicSnippet from '../clinic-snippet';

function KsuSnippet() {
  const serializedLocations = getEnvVariable('REACT_APP_KSU_Clinics');
  let locationIds = serializedLocations.split(';');
  locationIds = locationIds.filter((id) => id.trim() !== '');
  const [locationObj, setLocationObj] = useState({ url: '' });

  useEffect(() => {
    const { host } = window.location;
    if (host === 'ksu-health.qa.wellstar.org' || host === 'localhost:3000') {
      setLocationObj({ url: 'https://ksu-health.qa.wellstar.org' });
    } else if (host === 'ksu-health.stage.wellstar.org') {
      setLocationObj({ url: `https://${host}` });
    } else {
      setLocationObj({ url: 'https://ksu-health.wellstar.org/' });
    }
  }, []);

  return (
    <div id="KSU-Snippet" className="ksu-snippet py-3 d-flex border-bottom">
      <div className="ksu-main-container">
        <div className="d-flex align-items-center header">
          <div id="ksu-main-header-text" className="flex-grow-1 header-text">
            KSU Clinic
            - Next Available Practitioners
          </div>
          <div id="ksu-main-header-logos" className="d-flex align-items-center logo-container">
            <div className="img-container-wellstar">
              <img src={WSLogo} alt="wellstar logo" />
            </div>
            <div className="vertical-rule" />
            <div className="img-container-ksu">
              <img src={KSLogo} alt="ksu logo" />
            </div>
          </div>
        </div>
        <div className="horizontal-seperator mt-3" />
        <div id="ksu-main-text" className="d-flex mt-4">
          <SectionParagraph paragraph={ksuHealthText} />
        </div>
        <div id="ksu-card-header-text" className="d-flex mb-3 align-items-center card-header">
          <div>Click a time below to book with one of our next available practitioners</div>
        </div>
        <div id="ksu-card-container" className="d-flex container-snippet">
          {locationIds.map((id, index) => (
            <ClinicSnippet locationId={id} indexNumber={index} />
          ))}
        </div>
        <div className="d-flex mt-3 link-container">
          <div id="ksu-main-link-desktop" className="desktop-link">
            <a data-id="location-details-phone-number" className="view-more-link" target="_blank" rel="noreferrer" href={locationObj.url}>
              View more appointments and provider availability
            </a>
            &nbsp;
            <i className="fa fa-arrow-right" />
          </div>
          <div id="ksu-main-link-mobile" className="mobile-link">
            <a data-id="location-details-phone-number" className="view-more-link" target="_blank" rel="noreferrer" href={locationObj.url}>
              View more availability
            </a>
            &nbsp;
            <i className="fa fa-arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KsuSnippet;
