import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { IProfileLocation } from '../../types/profile-locations';
import { Physician } from '../../types/physicians';
import { Timeslot } from '../../types/schedules';
import { QuestionnaireData } from '../../types/questionnaire';
import QuestionnaireModal from '../student-questionnaire/questionnaire-modal';
import useDeferredPromise from '../../utils/deferred-promise';

interface IScheduleLauncherProps {
  location: IProfileLocation;
}

interface ICompletedHandlerProps {
  isCompleted: boolean;
  questionnaireData?: QuestionnaireData[];
}

export interface IScheduleLauncher {
  show(provider?: Physician, date?: Date, slot?: Timeslot): Promise<ICompletedHandlerProps>;
}

const ScheduleLauncher = forwardRef<IScheduleLauncher, IScheduleLauncherProps>((props, ref) => {
  const { location } = props;
  const [showModal, setShowModal] = useState(false);
  const [provider, setProvider] = useState<Physician | undefined>(undefined);
  const [slot, setSlot] = useState<Timeslot | undefined>(undefined);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const { defer, deferRef } = useDeferredPromise<ICompletedHandlerProps>();

  useImperativeHandle(
    ref,
    () => ({
      show(selectedProvider?: Physician, selectedDate?: Date, selectedSlot?: Timeslot) {
        setShowModal(true);
        if (selectedProvider && selectedSlot) {
          setProvider(selectedProvider);
          setDate(selectedDate);
          setSlot(selectedSlot);
        } else {
          setProvider(undefined);
          setDate(undefined);
          setSlot(undefined);
        }
        return defer().promise;
      },
    }),
  );

  const modalDismissHandler = () => {
    deferRef?.resolve({ isCompleted: false });
    setShowModal(false);
  };

  const modalCompletedHandler = (questionnaireData:QuestionnaireData[]) => {
    deferRef?.resolve({
      isCompleted: true,
      questionnaireData,
    });
    setShowModal(false);
  };

  return (
    <QuestionnaireModal
      location={location}
      provider={provider}
      date={date}
      slot={slot}
      isShowing={showModal}
      dismissHandler={modalDismissHandler}
      completedHandler={modalCompletedHandler}
    />
  );
});

export default ScheduleLauncher;
