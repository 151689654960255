import React, { useMemo, useRef } from 'react';
import { PracticeLocation } from '../../types/locations';
import { Physician } from '../../types/physicians';
import { IProfileLocation } from '../../types/profile-locations';
import ScheduleLauncher, { IScheduleLauncher } from '../scheduler-launcher';
import {
  Department, LocationSchedule, SchedulingInput, Timeslot, locationToDepartment,
} from '../../types/schedules';
import { MultiProviderSchedulePreview } from '../physician-card/MultiProviderSchedulePreview';

interface IClinicScheduleSnippetProps {
  providers: Physician[];
  selectedLocation: IProfileLocation;
  isVirtualVisit: boolean;
}

export default function ClinicScheduleSnippet(props: IClinicScheduleSnippetProps) {
  const {
    providers, selectedLocation, isVirtualVisit,
  } = props;
  const practiceLocation: PracticeLocation = useMemo(() => new PracticeLocation(selectedLocation), [selectedLocation]);
  const launcherRef = useRef<IScheduleLauncher>(null);

  const onTimeSelected = (selectedDate: Date, slot: Timeslot, department: Department, schedule: LocationSchedule, provider: Physician) => {
    launcherRef?.current?.show(provider, selectedDate, slot).then(({ isCompleted, questionnaireData }) => {
      if (!isCompleted) return;
      const input = new SchedulingInput('clinic', {
        isKSU: true,
        npid: provider.npid,
        providers,
        schedule,
        location: department,
        selectedDate,
        selectedTime: slot.arriveByText,
        isVirtualVisit,
        selectedSlotBlockType: slot.slotBlockType,
        visitTypeId: '855',
        additionalComments: questionnaireData,
        contactPhoneNumber: department?.phones?.at(0)?.number,
      });
      if (typeof (window as any).initSchedulingHelper !== 'function') return;
      (window as any).initSchedulingHelper(input);
    });
  };

  const viewFullSchedule = () => {
    launcherRef?.current?.show().then(({ isCompleted, questionnaireData }) => {
      if (!isCompleted) return;
      const dept = locationToDepartment(practiceLocation);
      const input = new SchedulingInput('clinic', {
        isKSU: true,
        location: dept,
        providers,
        isVirtualVisit,
        visitTypeId: '855',
        additionalComments: questionnaireData,
        contactPhoneNumber: dept?.phones?.at(0)?.number,
      });
      if (typeof (window as any).initSchedulingHelper !== 'function') return;
      (window as any).initSchedulingHelper(input);
    });
  };

  const isLoaded = providers && providers.length > 0 && practiceLocation;

  return (
    <div className="profile-schedule-preview">
      {isLoaded && (
        <>
          <MultiProviderSchedulePreview
            isVirtualVisit={isVirtualVisit}
            timeSelected={onTimeSelected}
            viewFullSchedule={viewFullSchedule}
            providers={providers}
            location={practiceLocation}
            id="physician-profile"
            isSnippet
          />
          <ScheduleLauncher
            ref={launcherRef}
            location={selectedLocation}
          />
        </>
      )}
    </div>
  );
}
