import React, { useMemo } from 'react';
import { PracticeLocation } from '../../types/locations';
import { IProfileLocation } from '../../types/profile-locations';
import './location-info.scss';

interface ILocationInfoProps {
  location: IProfileLocation;
}

export default function LocationInfo(props: ILocationInfoProps) {
  const { location } = props;

  const address = useMemo(() => {
    const loc = new PracticeLocation(location);
    return `${loc.streetAddress} ${loc.cityStateZip}`;
  }, [location]);

  return (
    <div className="modal-location-info">
      <div className="name-container">
        <h2>{location.Name}</h2>
        {location.IsWellStarLocation && (
          <img className="wmg-badge" src="/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/wmg-badge" alt="Wellstar Medical Group badge" />
        )}
      </div>
      <div className="address">{address}</div>
    </div>
  );
}
