import React from 'react';
import Header from '../components/header';
import Hero from '../components/hero';
import ClinicLocations from '../components/clinic-locations';
import NearbyPractitioners from '../components/nearby-practitioners';
import CookiePreferences from '../components/cookie-preferences';
import Footer from '../components/footer';

function Main() {
  return (
    <div className="app" id="KSU-Main-Containter">
      <Header />
      <Hero />
      <ClinicLocations />
      <NearbyPractitioners />
      <CookiePreferences />
      <Footer />
    </div>
  );
}

export default Main;
