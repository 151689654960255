import React, { useEffect, useMemo, useState } from 'react';
import { QuestionnaireData } from '../../types/questionnaire';
import './questionnaire-body.scss';

const YES = 'Yes';
const NO = 'No';

const Q1_TEXT = 'Are you a current KSU student?';
const Q2_TEXT = 'Have you had an appointment at a KSU clinic or Wellstar facility?';

interface IQuestionnaireBodyProps {
  setNextButtonEnabled: Function;
  setQuestionnaireData: Function;
}

export default function QuestionnaireBody(props: IQuestionnaireBodyProps) {
  const { setNextButtonEnabled, setQuestionnaireData } = props;
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');

  const isNextButtonEnabled = useMemo(() => {
    if (answer1 !== YES) return false;
    if (answer2 === '') return false;
    return true;
  }, [answer1, answer2]);

  const answerData = useMemo<QuestionnaireData[]>(() => [
    { key: Q1_TEXT, value: answer1 },
    { key: Q2_TEXT, value: answer2 },
  ], [answer1, answer2]);

  useEffect(() => {
    setNextButtonEnabled(isNextButtonEnabled);
    setQuestionnaireData(answerData);
  }, [answer1, answer2]);

  useEffect(() => {
    // When answer1 changes, reset answer 2
    setAnswer2('');
  }, [answer1]);

  const answer1Change = (e:React.ChangeEvent<HTMLInputElement>) => {
    setAnswer1(e.target.value);
  };

  const answer2Change = (e:React.ChangeEvent<HTMLInputElement>) => {
    setAnswer2(e.target.value);
  };

  return (
    <div className="questionnaire-body">
      <div className="question one" onChange={answer1Change}>
        <span className="title">{Q1_TEXT}</span>
        <label className="indent" htmlFor="q1-yes">
          <input type="radio" id="q1-yes" name="q1" value={YES} />
          Yes
        </label>
        <label className="indent" htmlFor="q1-no">
          <input type="radio" id="q1-no" name="q1" value={NO} />
          No
        </label>
      </div>
      {answer1 === NO && (
        <div className="indent critical-feedback">
          <span>
            Only current KSU students can schedule appointments at a KSU clinic.
          </span>
          <span>
            <a href="/physicians?specialty=primary%2Bcare&v=1" target="_blank">To proceed click here to find a local provider in an area near you.</a>
          </span>
        </div>
      )}
      {answer1 === YES && (
      <div className="question two" onChange={answer2Change}>
        <span className="title">{Q2_TEXT}</span>
        <label className="indent" htmlFor="q2-yes">
          <input type="radio" id="q2-yes" name="q2" value={YES} />
          Yes
        </label>
        <label className="indent" htmlFor="q2-no">
          <input type="radio" id="q2-no" name="q2" value={NO} />
          No
        </label>
      </div>
      )}
    </div>
  );
}
