/* eslint-disable no-param-reassign */
import { MatchingGeo } from '../../types/common';
import { PracticeLocation } from '../../types/locations';
import { IPhysicianSearchResponse, Physician, PhysicianResult } from '../../types/physicians';
import { hasAppointments } from '../../types/schedules';

const getPhysicianResultsItems = (searchResults:IPhysicianSearchResponse) => {
  if (searchResults === null || typeof searchResults !== 'object' || !Array.isArray(searchResults.matchingItems)) return [];
  const order: number[] = [];
  let locOrder: { [key: number]: MatchingGeo[] };
  if (Array.isArray(searchResults.matchingGeo) && searchResults.matchingGeo.length > 0) {
    locOrder = searchResults.matchingGeo.reduce<typeof locOrder>((map, geo) => {
      if (
        geo === null
        || typeof geo !== 'object'
        || typeof geo.matchingItemIndex !== 'number'
        || Number.isNaN(geo.matchingItemIndex)
        || typeof geo.geoIndex !== 'number'
        || Number.isNaN(geo.geoIndex)
        || searchResults.matchingItems.length <= geo.matchingItemIndex
      ) return map;

      if (order.indexOf(geo.matchingItemIndex) === -1) order.push(geo.matchingItemIndex);
      if (!Array.isArray(map[geo.matchingItemIndex])) map[geo.matchingItemIndex] = [];
      map[geo.matchingItemIndex].push(geo);
      return map;
    }, {});
  } else {
    locOrder = searchResults.matchingItems.reduce<typeof locOrder>((map, item, matchingItemIndex) => {
      order.push(matchingItemIndex);
      if (item.PhysicianLocations) {
        map[matchingItemIndex] = item.PhysicianLocations?.map((x, geoIndex) => ({
          matchingItemIndex,
          geoIndex,
          distance: null,
          lat: null,
          lon: null,
        }));
      }
      return map;
    }, {});
  }

  const physicianResults = order.map<PhysicianResult | null>((itemIdx) => {
    const geos = locOrder[itemIdx];
    const physician = new Physician(searchResults.matchingItems[itemIdx], geos);

    let locations: PracticeLocation[] = [];
    if (Array.isArray(geos)) {
      locations = geos
        .filter((x) => typeof x?.geoIndex === 'number' && !Number.isNaN(x.geoIndex) && x.geoIndex < physician.locationInformation.length)
        .map((x) => physician.locationInformation[x.geoIndex!]);
    } else locations = physician.locationInformation;
    const primLocation = locations.filter((x) => hasAppointments(physician, x)).filter((y) => y !== undefined).length > 0
      ? locations.filter((x) => hasAppointments(physician, x)).filter((y) => y !== undefined)[0]
      : locations[0];
    return {
      physician,
      primaryLocation: primLocation,
      matchedLocations: locations,
    };
  }).filter((x) => x !== null) as PhysicianResult[];

  return physicianResults;
};

export default getPhysicianResultsItems;
