/* eslint-disable */
import React from 'react';
import Context from '../../utils/context';
import styles from './ProfileImage.module.scss';

interface IProfileImageProps {
    imgUrl: string;
    imgAlt: string;
    badgeUrl?: string;
    badgeAlt?: string;
    borderless?: boolean;
    responsive?: boolean;
    size?: 'medium' | 'large' | 'xlarge';
}
export const ProfileImage: React.FunctionComponent<IProfileImageProps> = ({ imgUrl, imgAlt, badgeUrl, badgeAlt, borderless, responsive, size }) => {
    if (typeof responsive !== 'boolean') responsive = true;

    let wrapperClassNames = [styles.wrapper];
    if (borderless === true) wrapperClassNames.push(styles.borderless);
    if (responsive === true) wrapperClassNames.push(styles.responsive);
    if (size === 'large') wrapperClassNames.push(styles.large);
    else if (size === 'xlarge') wrapperClassNames.push(styles.xlarge);

    return (
        <Context.Consumer>
            {ctx => (
                <div className={wrapperClassNames.join(' ')}>
                    <img className={styles.img} src={ctx.basePath + imgUrl} alt={imgAlt} />
                    {typeof badgeUrl === 'string' && badgeUrl.length > 0 ? <img className={styles.badge} src={ctx.basePath + badgeUrl} alt={badgeAlt} /> : null}
                </div>
            )}
        </Context.Consumer>
    );
};
