import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  GoogleMap, InfoWindowF, MarkerClustererF, MarkerF,
} from '@react-google-maps/api';
import { Cluster } from '@react-google-maps/marker-clusterer';
import Context from '../../utils/context';
import { PracticeLocation } from '../../types/locations';
import { getDataBounds } from '../../utils/geo';

const MAX_ZOOM_LEVEL = 17;

interface IPractitionerMapProps {
  data: PracticeLocation[];
  maxZoomLevel: number;
  onMarkerClick: Function;
}

export default function PractitionersMap(props: IPractitionerMapProps) {
  const {
    data, maxZoomLevel, onMarkerClick,
  } = props;

  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [hoverMarker, setHoverMarker] = useState<PracticeLocation | null>(null);

  const dataBounds = useMemo(() => getDataBounds(data), [data]);

  const resetMap = useCallback(() => {
    if (!map) return;
    map.fitBounds(dataBounds);
    let zoom = map.getZoom();
    if (zoom && maxZoomLevel && zoom > maxZoomLevel) {
      map.setZoom(maxZoomLevel);
      zoom = maxZoomLevel;
    }
  }, [map, dataBounds, maxZoomLevel]);

  useEffect(() => {
    if (map) resetMap();
  }, [map, resetMap]);

  const onLoad = (currentMap: google.maps.Map) => {
    setMap(currentMap);
  };

  // Assumes the address is in the format "STREET, CITY, STATE, ZIP"
  // This will display only "STREET, CITY"
  const formatAddress = (address?: string | null) => address?.split(',').slice(0, 2).join(',');

  const onClusterClick = (cluster: Cluster) => {
    if (!cluster.map || !data.length) return;
    if (cluster.map.getZoom() === MAX_ZOOM_LEVEL) {
      const position = cluster.markers[0].getPosition();
      const [lat, lng] = [position?.lat(), position?.lng()];
      const location = data.find((loc) => loc.latitude === lat && loc.longitude === lng);
      if (location) onMarkerClick(location);
    }
  };

  return (
    <Context.Consumer>
      {(ctx) => (
        <GoogleMap
          mapContainerClassName="practitioners-map-container"
          clickableIcons={false}
          options={{
            maxZoom: MAX_ZOOM_LEVEL,
            zoomControl: true,
            zoomControlOptions: { position: window.google.maps.ControlPosition.RIGHT_TOP },
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: true,
            streetViewControlOptions: { position: window.google.maps.ControlPosition.RIGHT_TOP },
            rotateControl: false,
            fullscreenControl: false,
            gestureHandling: 'auto',
            keyboardShortcuts: false,
          }}
          onLoad={onLoad}
        >
          <MarkerClustererF
            zoomOnClick
            averageCenter
            gridSize={80}
            onClick={onClusterClick}
            styles={[
              {
                url: `${ctx.basePath}/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/groupmarker`,
                height: 80,
                width: 80,
                textColor: 'white',
                textSize: 24,
                anchorText: [-5, 0],
                anchorIcon: [68, 12],
              },
            ]}
          >
            {(clusterer) => (
              <>
                {data.map((location: PracticeLocation, index) => (
                  <MarkerF
                    clusterer={clusterer}
                    position={new window.google.maps.LatLng(location.latitude ?? 0, location.longitude)}
                    key={index}
                    icon={{
                      scaledSize: new window.google.maps.Size(56, 56),
                      anchor: new window.google.maps.Point(0, 56),
                      url: `${ctx.basePath}/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/wmglocationpin-active`,
                    }}
                    options={{}}
                    onMouseOver={() => setHoverMarker(location)}
                    onMouseOut={() => setHoverMarker(null)}
                    onClick={() => onMarkerClick(location)}
                  >
                    {location === hoverMarker && (
                    <InfoWindowF options={{ maxWidth: 200, disableAutoPan: false }}>
                      <div className="info-window">
                        <div className="name">{location.name}</div>
                        <div className="address">{formatAddress(location.address)}</div>
                      </div>
                    </InfoWindowF>
                    )}
                  </MarkerF>
                ))}
              </>
            )}
          </MarkerClustererF>
        </GoogleMap>
      )}
    </Context.Consumer>
  );
}
