import React from 'react';
import { Physician } from '../../types/physicians';
import { Timeslot } from '../../types/schedules';
import './timeslot-info.scss';

interface ITimeslotInfo {
  provider: Physician;
  date: Date;
  slot: Timeslot;
}

export default function TimeslotInfo(props: ITimeslotInfo) {
  const { provider, date, slot } = props;
  const formattedHourText = slot.hourText.startsWith('0') ? slot.hourText.substring(1) : slot.hourText;
  return (
    <div className="timeslot-info-container">
      <img src={provider.profileImgUrl} alt={provider.fullName} className="thumbnail" />
      <div className="description">
        <span className="name">
          {provider.fullName}
        </span>
        <span className="supplemental">
          {` at ${formattedHourText}`}
          {` on ${date.toLocaleString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}`}
        </span>
      </div>
    </div>
  );
}
