/* eslint-disable */
import { format, isAfter, isEqual, isTomorrow, parseISO, set } from 'date-fns';
import React from 'react';
import { PracticeLocation } from '../../types/locations';
import { Physician } from '../../types/physicians';
import {
    AllSlots,
    LocationSchedule,
    Timeslot,
    getAllTimeSlots,
    matchLocationToDepartment,
    setTimeSlots,
} from '../../types/schedules';
import styles1 from './PhysicianCard.module.scss';
import styles from './SchedulePreview.module.scss';

const getTodayDate = () => {
    const day = new Date();
    day.setHours(0, 0, 0, 0);
    return day;
};

interface IScheduleSnippetProps {
    physician: Physician;
    location: PracticeLocation;
    timeSelectedFunction: Function;
}

export const ScheduleSnippet: React.FunctionComponent<IScheduleSnippetProps> = ({ physician, location, timeSelectedFunction }) => {
    const isToday = (date: Date) => {
        let yay = isEqual(
            set(date, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
            set(new Date(), { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 })
        );
        return yay;
    };
    const getLocationSlots = (data: LocationSchedule) => {
        if (data == null) return null;
        // turn it into timeslots
        let daySlots = setTimeSlots(data);
        // call matchLocationToDepartment
        let chosenDepartment =
            data.departments !== null
                ? matchLocationToDepartment(location, physician?.schedule?.departments === undefined ? [] : physician?.schedule?.departments)
                : null;
        physician.schedule?.departments?.forEach(x => {
            x.active = false;
            x.disabled = false;
        });
        // call getAllSlots
        if (chosenDepartment !== null) {
            return getAllTimeSlots(
                chosenDepartment?.iDs?.filter(x => x.type === 'External'),
                daySlots
            );
        } else {
            return null;
        }
    };
    const getCurrentSlots = (slots: AllSlots) => {
        let counter = 0;
        let finalSlots = [] as any[];
        // have to separate by department
        let chosenDepartment =
            physician.schedule?.departments !== null
                ? matchLocationToDepartment(location, physician?.schedule?.departments === undefined ? [] : physician?.schedule?.departments)
                : null;
        let currentLocationIds =
            chosenDepartment?.iDs?.filter(x => x.type === 'External') === undefined ? [] : chosenDepartment?.iDs?.filter(x => x.type === 'External');
        // don't take old timeslots
        slots.daySlots
            .filter(x => currentLocationIds.filter(y => y.id === x.departmentId).length > 0)
            .forEach(daySlot => {
                daySlot.slots.forEach(timeSlot => {
                    let d = parseISO(daySlot.date);
                    d.setHours(timeSlot.hour);
                    d.setMinutes(parseInt(timeSlot.hourText.split(':')[1]));
                    d.setSeconds(0);
                    if (counter < 3 && isAfter(d, getTodayDate())) {
                        finalSlots.push({
                            date: d,
                            dateString: isToday(d) ? 'Today' : isTomorrow(d) ? 'Tomorrow' : format(d, 'MMM dd'),
                            timeSlot: timeSlot,
                        });
                        counter++;
                    }
                });
            });
        if (finalSlots.length === 0) noSlotRender();
        return finalSlots;
    };

    const noSlotRender = () => {
        if (!location.locationTypes.includes('Lab Services')) {
            const stopBubble = (event: any) => {
                event.stopPropagation();
            };
            return (
                <div className={`${styles.scheduleNoSlots} ${styles.schedule}`}>
                    <div className={styles.scheduleNoAppts}>
                        Not seeing the appointment you want? <br className={styles.noBreakMobile} /> Call our office for help scheduling.
                    </div>
                    <a href={`tel:${location.locationContactPhone}`} onClick={stopBubble} className={`${styles1.viewMoreAvailability} ${styles.semiBold}`}>
                        {location.locationContactPhone}
                    </a>
                </div>
            );
        } else {
            return <></>;
        }
    };

    const timeSelectedHandler = (date: Date, slot: Timeslot) => {
        date.setHours(slot.hour);
        date.setMinutes(parseInt(slot.hourText.split(':')[1]));
        date.setSeconds(0);
        if (typeof timeSelectedFunction == 'function' && timeSelectedFunction != null) timeSelectedFunction(date, slot);
    };

    if (physician.schedule === undefined || physician.schedule === null) return noSlotRender();
    let allSlots = getLocationSlots(physician.schedule);
    if (allSlots === null || allSlots === undefined) return noSlotRender();
    let slots = getCurrentSlots(allSlots);

    return (
        <div className={`${styles.schedule} ${slots.length > 0 ? styles.horizontal_scroll : ''}`}>
            {slots.length > 0
                ? slots.map(slot => (
                    <button
                        key={slot.timeSlot.hourText + slot.date}
                        className={styles.scheduleButtonSnippet}
                        onClick={() => timeSelectedHandler(slot.date, slot.timeSlot)}
                    >
                        {slot.timeSlot.hourText}
                        <span className={styles.semiBold}> {slot.dateString}</span>
                    </button>
                ))
                : noSlotRender()}
        </div>
    );
};
