export default function getEnvVariable(varName: string, fallbackData: string = '') {
  let envData;

  try {
    envData = process.env[varName];
  } finally {
    if (!envData) envData = fallbackData;
  }
  return envData;
}
