import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import ClinicAddress from '../clinic-address';
import ClinicHeader from '../clinic-header';
import LocationContact from '../location-contact';
import ClinicScheduleSnippet from '../clinic-schedule-snippet';
import { getLocationDetails } from '../../api/LocationDetails';
import { IProfileLocation } from '../../types/profile-locations';
import { LocationDetails } from '../location-details/LocationDetails';
import { PracticeLocation } from '../../types/locations';
import { Physician } from '../../types/physicians';
import './clinic-snippet.scss';

interface IClinicProps {
  locationId: string;
  indexNumber?: number;
}

function ClinicSnippet(props: IClinicProps) {
  const { locationId, indexNumber } = props;
  const [location, setLocation] = useState<IProfileLocation | null>(null);

  const fetchData = useCallback(async () => {
    if (!locationId) return;
    setLocation(await getLocationDetails(locationId));
  }, [locationId]);

  useEffect(() => {
    // Request data on initial load
    fetchData();
  }, [fetchData]);

  const providers = useMemo((): Physician[] => {
    if (location && location.ListOfPhysician) {
      return location.ListOfPhysician
        .map((provider) => new Physician(provider));
    }
    return [];
  }, [location]);

  const practiceLocation = useMemo(() => new PracticeLocation(location), [location]);
  return (
    <div id={`clinic-container-snippet-${indexNumber}`} className="clinic-container-snippet">
      {location && location.ListOfPhysician?.length && (
      <>
        <ClinicHeader location={practiceLocation} indexNumber={indexNumber} isPartOfWellstarGroup />
        <ClinicAddress location={practiceLocation} indexNumber={indexNumber} isSnippet />
        <LocationContact location={practiceLocation} indexNumber={indexNumber} />
        <LocationDetails location={practiceLocation} indexNumber={indexNumber} isSnippet />
        <ClinicScheduleSnippet
          selectedLocation={location}
          isVirtualVisit={false}
          providers={providers}
        />
      </>
      )}
    </div>
  );
}

export default ClinicSnippet;
