import React from 'react';
import PropTypes from 'prop-types';
import './section-title.scss';

interface ISectionTitleProps {
  title: string;
}

function SectionTitle(props: ISectionTitleProps) {
  const { title } = props;
  return (
    <h2 className="section-title">{title}</h2>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
