import React from 'react';
import './App.scss';
import 'bootstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import KsuSnippet from './components/ksu-snippet';
import KsuIframePage from './pages/sandbox/KsuIframePage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/ksu-widget" element={<KsuSnippet />} />
        <Route path="/iframe-sandbox" element={<KsuIframePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
