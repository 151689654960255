/* eslint-disable */
import React, { FunctionComponent } from 'react';
import Context from '../../utils/context';
import { FeatureChip } from '../../types/common';
import styles from './FeatureChipTag.module.scss';

export const FeatureChipTag: FunctionComponent<{
    chip: FeatureChip;
    className?: string;
    summaryCardMode?: boolean;
    automationId?: string;
}> = ({ chip, className, summaryCardMode, automationId }) => {
    if (chip === null || typeof chip !== 'object') return null;

    if (typeof className !== 'string' || className.length === 0) className = styles.featureChipTag;
    else className += ` ${styles.featureChipTag}`;

    if (summaryCardMode || chip?.title === 'Virtual Visits') {
        className += ` ${styles.featureChip_VirtualVisit}`;
    }

    const chipTitleWithOutSpaces = chip.title ? chip.title.replace(/\s/g, '') : '';

    const elementId = automationId ? `${automationId}-${chipTitleWithOutSpaces}` : chipTitleWithOutSpaces;

    return (
        <Context.Consumer>
            {ctx => (
                <div
                    className={className}
                    style={{ background: '#' + chip.color }}
                    id={`Feature-Chip-Tag-${elementId}`}
                    aria-label={`This physician is enabled for ${chip.title}`}
                >
                    {chip.icon === null || typeof chip.icon !== 'object' ? null : (
                        <img src={ctx.basePath + chip.icon.imagePath} alt={chip.icon.imageAlt ?? ''} title={chip.icon.imageTitle ?? ''} />
                    )}
                    {summaryCardMode || chip?.title === 'Virtual Visits' ? <i className="fa fa-video"></i> : null}
                    {chip.title}
                </div>
            )}
        </Context.Consumer>
    );
};

export const FeatureChipCard: FunctionComponent<{ chip: FeatureChip; className?: string }> = ({ chip, className }) => {
    if (chip === null || typeof chip !== 'object') return null;

    if (typeof className !== 'string' || className.length === 0) className = styles.featureChipCard;
    else className += ` ${styles.featureChipCard}`;

    return (
        <Context.Consumer>
            {ctx => (
                <div className={className}>
                    {chip.icon === null || typeof chip.icon !== 'object' ? null : (
                        <div className={styles.featureChipIcon}>
                            <img src={ctx.basePath + chip.icon.imagePath} alt={chip.icon.imageAlt ?? ''} title={chip.icon.imageTitle ?? ''} />
                        </div>
                    )}
                    <div className={styles.featureChipText}>
                        <div className={styles.featureChipTitle}>{chip.title}</div>
                        <div className={styles.featureChipDescription} dangerouslySetInnerHTML={{ __html: chip.abstract ?? '' }} />
                    </div>
                </div>
            )}
        </Context.Consumer>
    );
};
