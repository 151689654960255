/* eslint-disable */
import { PracticeLocation } from '../types/locations';
import { VIRTUAL_VISIT_ID } from '../utils/constants';

export const findVirtualVisitDepartmentIds = (locations: PracticeLocation[]) => {
    return locations
        .filter(location => location.openSchedulingDepartmentId)
        .flatMap(location => [location.openSchedulingDepartmentId])
        .join(',');
};

export const getVirtualVisitPracticeLocation = (primaryLocation: PracticeLocation, locations: PracticeLocation[]) => {
    const vvLocation = new PracticeLocation(primaryLocation);
    vvLocation.itemId = VIRTUAL_VISIT_ID;
    vvLocation.openSchedulingDepartmentId = findVirtualVisitDepartmentIds(locations);
    return vvLocation;
};
