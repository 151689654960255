import React from 'react';
import Context from '../../utils/context';
import { PracticeLocation } from '../../types/locations';
import './clinic-header.scss';

interface IClinicHeaderProps {
  location: PracticeLocation;
  isPartOfWellstarGroup: boolean;
  indexNumber?: number;
}

function ClinicHeader(props: IClinicHeaderProps) {
  const { location, isPartOfWellstarGroup, indexNumber } = props;
  return (
    <Context.Consumer>
      {(ctx) => (
        <div id={`profile-location-card-header-${indexNumber}`} className="profile-locations-card-header">
          <div className="interior-header">
            <h3>{location?.name}</h3>
          </div>
          {isPartOfWellstarGroup && (
            <div className="brandmark-container">
              <img
                src={`${ctx.basePath}/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/wmgbrandmark`}
                alt="Part of Wellstar Medical Group"
              />
            </div>
          )}
        </div>
      )}
    </Context.Consumer>
  );
}

export default ClinicHeader;
