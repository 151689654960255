/* eslint-disable */ 

const METHODS = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
};

const RESPONSE_TYPES = {
    JSON: 'json',
    TEXT: 'text',
    BLOB: 'blob',
};

const ALLOWED_METHODS = [METHODS.GET, METHODS.POST];
const ALLOWED_RESPONSE_TYPES = [RESPONSE_TYPES.JSON, RESPONSE_TYPES.TEXT];

const defaultPayload = {
    headers: {
        'content-type': 'application/json',
    },
    method: METHODS.GET,
    mode: 'cors' as RequestMode,
};

interface IInitData {
    mode?: RequestMode;
    method?: string;
    headers?: any;
    body?: any;
}

const defaultOptions = {
    responseFormat: RESPONSE_TYPES.JSON,
};

const getInitData = (initData: IInitData = {}) => {
    if (initData.method && !ALLOWED_METHODS.includes(initData.method)) {
        throw new Error(`'${initData.method}' is not an allowed method.`);
    }

    const data: IInitData = {
        method: initData.method ?? defaultPayload.method,
        headers: initData.headers ?? defaultPayload.headers,
        mode: initData.mode ?? defaultPayload.mode,
    };

    if (initData.body) {
        if (data.method && data.method.toUpperCase() === METHODS.GET) {
            throw new Error(`'${METHODS.GET}' requests cannot contain a body.`);
        }

        if (initData.body instanceof FormData) {
            data.body = initData.body;
        } else if (initData.body instanceof URLSearchParams) {
            data.body = initData.body.toString();
        } else {
            // Ensure the json is safely parseable
            try {
                const body = JSON.parse(initData.body);
                data.body = JSON.stringify(body);
            } catch (ex) {}
        }
    }
    return data;
};

const request = async (url: string, body: any = null, initData: IInitData = {}, options: any = {}) => {
    const data = getInitData({ ...initData, body });
    options = { ...defaultOptions, ...options };

    let response: any = await fetch(url, data);


    if (!response.ok) {
        throw new Error(`API response error: ${response}`);
    }

    const responseFormat = options.responseFormat;
    if (!responseFormat) {
        throw new Error('Response format is required.');
    }

    if (!ALLOWED_RESPONSE_TYPES.includes(responseFormat)) {
        throw new Error(`Response format '${responseFormat}' is not allowed.`);
    }

    // Handle case where no content is returned
    if (response.status === 204) {
        return null;
    }
    return await response[responseFormat]();
};

const get = (url: string, init: any = {}, options: any = {}) => {
    return request(
        url,
        null,
        {
            ...init,
            method: METHODS.GET,
        },
        options
    );
};

const post = (url: string, body: any = new URLSearchParams(), init: any = {}, options: any = {}) => {
    const token: HTMLInputElement | null = document.querySelector('input[name=__RequestVerificationToken]');
    return request(
        url,
        body,
        {
            headers: {
                'content-type': 'application/json',
                __RequestVerificationToken: token?.value,
            },
            ...init,
            method: METHODS.POST,
        },
        options
    );
};

export const client = {
    request: request,
    get: get,
    post: post,
};

export { METHODS, ALLOWED_METHODS };
