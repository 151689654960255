import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import ClinicAddress from '../clinic-address';
import ClinicHeader from '../clinic-header';
import ClinicPractitionerAvailability from '../clinic-practitioner-availability';
import ClinicSchedule from '../clinic-schedule';
import ClinicVisitTypeToggle from '../clinic-visit-type-toggle';
import { getLocationDetails } from '../../api/LocationDetails';
import { IProfileLocation } from '../../types/profile-locations';
import { LocationDetails } from '../location-details/LocationDetails';
import { PracticeLocation } from '../../types/locations';
import { Physician } from '../../types/physicians';
import './clinic.scss';

interface IClinicProps {
  locationId: string;
}

function Clinic(props: IClinicProps) {
  const { locationId } = props;
  const [location, setLocation] = useState<IProfileLocation | null>(null);
  const [isVirtualVisit, setIsVirtualVisit] = useState(false);

  const fetchData = useCallback(async () => {
    if (!locationId) return;
    setLocation(await getLocationDetails(locationId));
  }, [locationId]);

  useEffect(() => {
    // Request data on initial load
    fetchData();
  }, [fetchData]);

  const providers = useMemo((): Physician[] => {
    if (location && location.ListOfPhysician) {
      return location.ListOfPhysician.map((provider) => new Physician(provider));
    }
    return [];
  }, [location, isVirtualVisit]);

  const practiceLocation = useMemo(() => new PracticeLocation(location), [location]);

  return (
    <div className="clinic-container">
      {location && location.ListOfPhysician?.length && (
        <>
          <ClinicHeader location={practiceLocation} isPartOfWellstarGroup />
          <ClinicAddress location={practiceLocation} />
          <ClinicVisitTypeToggle
            hasVirtualVisitToggle={location.ListOfPhysician.some((physician) => physician.AllowVirtualVisits)}
            onToggle={(toggle: boolean) => setIsVirtualVisit(toggle)}
          />
          <ClinicSchedule
            selectedLocation={location}
            isVirtualVisit={isVirtualVisit}
            providers={providers}
          />
          <LocationDetails location={practiceLocation} />
          <ClinicPractitionerAvailability location={location} isVirtualVisit={isVirtualVisit} />
        </>
      )}
    </div>
  );
}

export default Clinic;
