/* eslint-disable */
import { FormHelperText, IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { add, closestTo, format, isAfter, isBefore, isEqual, isSameDay, isToday, isTomorrow, isValid, max, min, parseISO, set } from 'date-fns';
import { addMonths } from 'date-fns/esm';
import React, { useEffect } from 'react';
import { PracticeLocation } from '../../types/locations';
import { Physician } from '../../types/physicians';
import {
    AllSlots,
    DaySlots,
    Department,
    LocationSchedule,
    Timeslot,
    combineLocationSchedules,
    getAllTimeSlots,
    locationToDepartment,
    setTimeSlots,
} from '../../types/schedules';
import { Loading } from '../search-loader/Loading';
import styles from './SchedulePreview.module.scss';

const theme = createTheme({
    palette: {
        primary: {
            main: '#394854',
        },
        secondary: {
            main: '#394854',
        },
    },
    typography: {
        fontFamily: 'Work Sans',
    },
});

const getTodayDate = () => {
    const day = new Date();
    day.setHours(0, 0, 0, 0);
    return day;
};

interface ISchedulePreviewProps {
    providers: Physician[];
    location: PracticeLocation;
    timeSelected: Function;
    viewFullSchedule: Function;
    dataFetched?: Function;
    id: string;
    isVirtualVisit: boolean;
    isSnippet?: boolean;
}

interface ISchedulePreviewState {
    providersState: IProviderState[];
    chosenDepartment: Department;
    loading: boolean;
    collapsed: boolean;
    currentDate: Date;
    showDatePicker: boolean;
    open: boolean;

    daySlots: AllSlots | null;
    currentSlots: DaySlots[];
    isEmptyData: boolean;
}

interface IProviderState {
    provider: Physician;
    data?: LocationSchedule;
    daySlots: AllSlots | null;
    currentSlots: DaySlots[];
    datesQueried: string[];
}

export class MultiProviderSchedulePreview extends React.PureComponent<ISchedulePreviewProps, ISchedulePreviewState> {
    constructor(props: ISchedulePreviewProps) {
        super(props);

        this.state = {
            daySlots: null,
            currentSlots: [],
            providersState: props.providers.map((provider) => {
                return {
                    provider,
                    data: new LocationSchedule(),
                    currentSlots: [],
                    daySlots: null,
                    datesQueried: [],
                };
            }),
            chosenDepartment: locationToDepartment(props.location),
            loading: true,
            currentDate: getTodayDate(),
            showDatePicker: false,
            collapsed: true,
            open: false,
            isEmptyData: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: ISchedulePreviewProps, prevState: ISchedulePreviewState) {
        // Typical usage (don't forget to compare props):
        if (this.props.location !== prevProps.location) {
            this.getSchedule(this.props.providers, false, true);
        } else if (this.props.isVirtualVisit !== prevProps.isVirtualVisit) {
            this.resetProviderState();
            this.getSchedule(this.props.providers, true);
        } else if (!isEqual(this.state.currentDate, prevState.currentDate)) {
            this.getSchedule(this.props.providers);
        }
    }

    init = () => {
        this.getSchedule(this.props.providers);
    };

    getSchedule = async (providersData: Physician[], forceDataLoad: boolean = false, locationChangeOverride: boolean = false) => {
        const allState: IProviderState[] = [];
        for (let i = 0; i < providersData.length; i += 1) {
            if (providersData[i].enableOnlineScheduling) {
                const physState = await this.getPhysicianSchedule(providersData[i], forceDataLoad, locationChangeOverride);
                if (physState) {
                    allState.push(physState);
                }
            }
        }
        const allSlots:AllSlots = { daySlots: this.getAllProviderSlots(providersData, allState)};
        if (!allSlots || !allSlots.daySlots.length) {
          // Empty data
          this.setState({
            loading: false,
            isEmptyData: true,
            daySlots: { daySlots: [] },
          });
          return;
        }

        // Extra step to filter out slots that don't match the location department id
        // Ideally this should be done on the backend or in a centralized data transformation layer
        const validLocationDeptIds = this.state.chosenDepartment.iDs?.map(dept => dept.id) || [];
        allSlots.daySlots = allSlots.daySlots.filter((day) => validLocationDeptIds.includes(day.departmentId));

        const currentSlots = this.getCurrentSlots(this.state.chosenDepartment, allSlots);
        this.setState({
            daySlots: allSlots,
            currentSlots: currentSlots,
            loading: false,
            isEmptyData: false,
        });
    };

    getProviderStateFromId = (npid: string) => {
        const found = this.state.providersState.find((provider) => provider.provider.npid === npid);
        if (!found) return undefined;
        return found;
    };

    setProviderState = (npid: string, state:IProviderState) => {
        const index = this.state.providersState.findIndex((provider) => provider.provider.npid === npid);
        const providers = this.state.providersState;
        providers[index] = state;
        this.setState({
            providersState: providers,
        });
    }

    resetProviderState = () => {
        this.setState({
            providersState: this.props.providers.map((provider) => {
                return {
                    provider,
                    data: new LocationSchedule(),
                    currentSlots: [],
                    daySlots: null,
                    datesQueried: [],
                };
            }),
        });
    }

    getAllProviderSlots = (providersData: Physician[], allState: IProviderState[]) => {
        const allSlots:DaySlots[] = [];
        for (let iProvider = 0; iProvider < providersData.length; iProvider += 1) {
            const npid = providersData[iProvider].npid;

            const provider = allState.find((state) => state.provider.npid === npid);
            if (!provider) continue;

            const daySlots = provider.daySlots?.daySlots;
            if (daySlots && daySlots.length > 0) {
                for (let iDay = 0; iDay < daySlots.length; iDay += 1) {
                    const daySlot = daySlots[iDay];

                    const existingDay = allSlots.find((day) => day.date === daySlot.date);
                    if (existingDay) {
                        existingDay.slots = [...existingDay.slots, daySlot.slots].flat();
                    } else {
                        allSlots.push(daySlot);
                    }
                }
            }
        }

        // Make a pass to de-dup and sort by date
        allSlots.sort((a, b) => a.date.localeCompare(b.date));
        const filteredSlots = allSlots.map((day) => {
            const slots = day.slots;
            const groupedSlots: { [hourText: string]: Timeslot[]; } = {};
            slots.forEach((slot) => {
                if (!groupedSlots[slot.hourText]) {
                    groupedSlots[slot.hourText] = [];
                }
                groupedSlots[slot.hourText].push(slot);
            });
            const resultSlots: Timeslot[] = [];
            for (const hourText in groupedSlots) {
                const slotGroup = groupedSlots[hourText];
                // Deliberately pick a random time slot if there's a duplicate time among multiple providers
                const randomIndex = Math.floor(Math.random() * slotGroup.length);
                resultSlots.push(slotGroup[randomIndex]);
            }
            resultSlots.sort((a, b) => a.hour - b.hour || a.hourText.localeCompare(b.hourText));
            day.slots = resultSlots;
            return day;
        });

        return filteredSlots;
    };

    getPhysicianSchedule = async (provider: Physician, forceDataLoad: boolean = false, locationChangeOverride: boolean = false) => {
        // this should include the validation of whether the schedule is already in mem
        const startDate = this.state.currentDate;
        const endSearchDate = add(startDate, { days: 3 });
        const endDate = addMonths(startDate, 3);
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndSearchDate = format(endSearchDate, 'yyyy-MM-dd');
        let dataPresent = false;
        const providerState = this.getProviderStateFromId(provider.npid);
        if (!providerState) return undefined;

        const { isVirtualVisit } = this.props;

        const addedToQueriedDates = providerState.datesQueried.concat(this.getDateArray(startDate, endDate).map(x => format(x, 'yyyy-MM-dd')));

        if (forceDataLoad) {
          // Clears the record of fetched data dates, forces a fresh pull from the API
          providerState.datesQueried = [];
        }

        if (providerState.datesQueried.includes(formattedStartDate) && providerState.datesQueried.includes(formattedEndSearchDate)) {
            dataPresent = true;
        }

        if (!dataPresent) {
            this.setState({
                loading: true,
            });

            // Hard-coded for now. In-Person is 855 and Virtual Visit is 645.
            const visitTypeId = isVirtualVisit ? 645 : 855;

            const apiUrl = `/api/scheduling/providers/schedules?providerId=${provider.npid}&startDate=${format(
                startDate,
                'yyyy-MM-dd'
            )}&endDate=${format(endDate, 'yyyy-MM-dd')}&visitTypeId=${visitTypeId}`;
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    mode: 'cors',
                });

                if (!response.ok) {
                    const newState = {
                        ...providerState,
                        daySlots: { daySlots: [] },
                        datesQueried: addedToQueriedDates,
                        currentSlots: [],
                    };
                    this.setProviderState(provider.npid, newState);
                    return newState;
                }

                const schedule: LocationSchedule = await response.json();
                schedule?.departments?.forEach(x => {
                    x.active = false;
                    x.disabled = false;
                });
                let newSlots = this.getLocationSlots(schedule);

                // Each slot needs to be associated with a provider
                newSlots?.daySlots.forEach((day) => {
                    day.slots.forEach((slot) => {
                        slot.provider = provider;
                      });
                  });

                const chosenDepartment = this.state.chosenDepartment;
                var currentSlots = [] as DaySlots[];
                if (newSlots != null && chosenDepartment != null) {
                    currentSlots = this.getCurrentSlots(chosenDepartment, newSlots, locationChangeOverride);
                }

                const newState = {
                    ...providerState,
                    daySlots: newSlots,
                    data: combineLocationSchedules(providerState.data ? [providerState.data, schedule] : [], chosenDepartment),
                    datesQueried: addedToQueriedDates,
                    currentSlots: currentSlots,
                };

                this.setProviderState(provider.npid, newState);
                return newState;

            } catch (e) {

            }
        } else {
            let newSlots = providerState.daySlots;

            if (!newSlots || !newSlots.daySlots.length) {
                return {
                    provider,
                    daySlots: { daySlots: [] },
                    datesQueried: addedToQueriedDates,
                    currentSlots: [],
                }
            }

            const chosenDepartment = this.state.chosenDepartment;
            let currentSlotsHere = [] as DaySlots[];

            if (newSlots != null && chosenDepartment != null) {
                currentSlotsHere = this.getCurrentSlots(chosenDepartment, newSlots, locationChangeOverride);
            }

            const newState = {
                ...providerState,
                daySlots: newSlots,
                data: providerState.data,
                currentSlots: currentSlotsHere,
            };

            this.setProviderState(provider.npid, newState);
            return newState;
        }
    };

    getLocationSlots = (data: LocationSchedule) => {
        if (data == null) return null;
        // turn it into timeslots
        let daySlots = setTimeSlots(data);
        let chosenDepartment = this.state.chosenDepartment;
        // call getAllSlots
        if (chosenDepartment !== null) {
            // No no no no no
            // this.setState({
            //     chosenDepartment: chosenDepartment,
            // });
            return getAllTimeSlots(
                chosenDepartment?.iDs?.filter(x => x.type === 'External'),
                daySlots
            );
        } else {
            return null;
        }
    };

    getCurrentSlots = (chosenDepartment: Department, daySlots: AllSlots, locationChangeOverride: boolean = false) => {
        let today =
            (this.state.currentDate !== null || this.state.currentDate !== undefined) && !locationChangeOverride ? this.state.currentDate : getTodayDate();
        let currentLocationIds =
            chosenDepartment?.iDs?.filter(x => x.type === 'External') === undefined ? [] : chosenDepartment?.iDs?.filter(x => x.type === 'External');
        let currentSlots = [] as DaySlots[];
        let allDaySlots = daySlots?.daySlots?.filter(x => currentLocationIds.filter(y => y.id === x.departmentId).length > 0).map(z => parseISO(z.date));
        let closestDate = closestTo(today, allDaySlots);
        let firstIndex = daySlots?.daySlots?.findIndex(x => x.date === format(closestDate !== undefined ? closestDate : today, 'yyyy-MM-dd'));
        let firstDay = daySlots?.daySlots[firstIndex];
        if (this.props.isSnippet)   
            firstDay.slots.length = 1; 
        if (firstDay !== undefined) {
            currentSlots.push(firstDay);
        }
        let secondDay = daySlots?.daySlots[firstIndex + 1];
        if (this.props.isSnippet)   
            secondDay.slots.length = 1; 
        if (secondDay !== undefined) {
            currentSlots.push(secondDay);
        }
        let thirdDay = daySlots?.daySlots[firstIndex + 2];
        if (this.props.isSnippet)   
            thirdDay.slots.length = 1;
        if (thirdDay !== undefined) {
            currentSlots.push(thirdDay);
        }
        // Don't change state in a get() method!
        // this.setState({
        //     currentSlots: currentSlots,
        // });

        return currentSlots;
    };

    formatDate = (date: string) => {
        let today = new Date();
        let dateTime = parseISO(date);
        if (isSameDay(today, dateTime)) {
            return (
                <div className={styles.scheduleColumnTitle}>
                    <span>Today</span>
                    <br />
                    {format(dateTime, ' MMM dd')}
                </div>
            );
        } else if (isTomorrow(dateTime)) {
            return (
                <div className={styles.scheduleColumnTitle}>
                    <span>Tomorrow</span>
                    <br />
                    {format(dateTime, ' MMM dd')}
                </div>
            );
        } else {
            format(dateTime, 'iii MMM dd');
            return (
                <div className={styles.scheduleColumnTitle}>
                    <span>{format(dateTime, 'iii')}</span>
                    <br />
                    {format(dateTime, ' MMM dd')}
                </div>
            );
        }
    };
    getDateArray = (dateStart: Date, dateEnd: Date) => {
        let dates = [];
        let thisDate = dateStart;
        while (thisDate < dateEnd) {
            dates.push(new Date(thisDate));
            thisDate = add(thisDate, { days: 1 });
        }
        return dates;
    };
    setCurrentDate = (newValue: any) => {
        if (isValid(newValue) && isAfter(newValue, new Date()) && isBefore(newValue, add(new Date(), { years: 2 }))) {
            this.setState({
                currentDate: newValue,
                collapsed: true,
            });
        }
    };
    dateExists = (date: Date) => {
        const parsedDate = date.toISOString().split('T')[0];
        return this.state.providersState.some((provider) => {
            return provider.daySlots?.daySlots.some((item) => {
                return parsedDate === item.date;
            });
        });
    };
    tagAvailableDates = (date: Date, selectedDates: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
        let datePresent = this.dateExists(date);
        if (datePresent === true) {
            return (
                <PickersDay
                    {...pickersDayProps}
                    sx={{ backgroundColor: 'rgba(0, 151, 208, 0.2)', [`&&.${pickersDayClasses.selected}`]: { backgroundColor: '#0097D0' } }}
                />
            );
        } else if (isToday(date)) {
            return <PickersDay {...pickersDayProps} sx={{ color: '#0097D0', border: '1px solid #0097D0 !important', borderRadius: '40px' }} />;
        } else {
            pickersDayProps.className = 'Mui-disabled';
            return <PickersDay {...pickersDayProps} sx={{ [`&&.${pickersDayClasses.selected}`]: { backgroundColor: '#0097D0' } }} />;
        }
    };
    areSameDays = (date: Date, date2: Date) => {
        let comparison = isEqual(
            set(date, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
            set(date2, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 })
        );
        return comparison;
    };
    isToday = () => {
        let yay = isEqual(
            set(this.state.currentDate, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
            set(new Date(), { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 })
        );
        return yay;
    };
    datesBefore = () => {
        if (format(this.state.currentDate, 'yyyy-MM-dd') === this.state.daySlots?.daySlots[0].date) {
            return;
        }
        var newDate;
        let index = this.state.daySlots?.daySlots?.findIndex(x => x.date === format(this.state.currentDate, 'yyyy-MM-dd'));
        if (index !== undefined && this.state.daySlots?.daySlots !== undefined) {
            var dateString;
            if (index >= 3) {
                dateString = this.state.daySlots?.daySlots[index - 3].date;
            } else {
                dateString = this.state.daySlots?.daySlots[0].date;
            }

            newDate = parseISO(dateString);
        } else {
            newDate = add(this.state.currentDate, { days: -3 });
        }
        this.setState({
            currentDate: newDate,
            collapsed: true,
        });
    };
    datesAfter = () => {
        var newDate;
        let index = this.state.daySlots?.daySlots?.findIndex(x => x.date === format(this.state.currentDate, 'yyyy-MM-dd'));
        if (index !== undefined && this.state.daySlots?.daySlots !== undefined) {
            var dateString;
            if (index + 3 >= this.state.daySlots.daySlots.length - 3) {
                newDate = add(parseISO(this.state.daySlots?.daySlots[this.state.daySlots.daySlots.length - 1].date), { days: 1 });
            } else {
                dateString = this.state.daySlots?.daySlots[index + 3].date;
                newDate = parseISO(dateString);
            }
        } else {
            newDate = add(this.state.currentDate, { days: 3 });
        }
        this.setState({
            currentDate: newDate,
            collapsed: true,
        });
    };
    hideDatePicker = () => {
        this.setState({
            showDatePicker: false,
        });
    };
    showDatePicker = () => {
        this.setState({
            showDatePicker: true,
        });
    };
    moreSelectedFunction = () => {
        this.setState({
            collapsed: false,
        });
    };
    dateInputError = () => {
        console.log('validation error');
    };
    onBookAppointment = () => {
      this.props.viewFullSchedule();
    };
    handleOpen = () => {
        this.setState({
            open: true,
        });
    };
    handleClose = () => {
        this.setState({
            open: false,
        });
    };
    timeslotSelected = (date: Date, slot: Timeslot, department: Department) => {
        if (!slot || !slot.provider) {
            throw new Error('Time slot missing id');
        }
        const providerState = this.getProviderStateFromId(slot.provider.npid);
        if (providerState) {
            this.props.timeSelected(date, slot, department, providerState.data, providerState.provider);
        }
    };
    // make sure selectedEvent saves the data somewhere so that it can be retrieved and sent when "book an appointment is clicked"
    render() {
        //this.props.dataFetched(this.state.data, this.state.chosenDepartment);
        const { daySlots, currentSlots, loading, isEmptyData } = this.state;
        const { id, isSnippet } = this.props;
        const loadingStyle = isSnippet ? { backgroundColor: 'inherit', minHeight:'0px'  } : { backgroundColor: 'inherit' } ;
        const leftArrowDisabled = '/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/left-arrow-disabled-scheduling';
        const rightArrow = '/-/media/Themes/WellStar/Org/WellStarOrg/WellStarTheme/images/right-arrow-scheduling';
        return (
            <>
                {loading && (
                    <Loading customStyles={loadingStyle} />
                )}
                {!loading && isEmptyData && !isSnippet && (
                    <div className={styles.scheduleEmptyData}>
                        <div className={styles.emptyDataContainer}>
                            <i className="fa fa-exclamation-circle"></i>
                            Online scheduling is unavailable at this time. Please call the clinic to schedule an appointment.
                        </div>
                    </div>
                )}
                {!loading && isEmptyData && isSnippet && (
                    <div className={styles.unavailableSnippet}>
                        <i className="fa fa-exclamation-circle pe-2 pt-1"></i>
                        <div>Online scheduling is unavailable at this time. Please call the clinic to schedule an appointment.</div>
                    </div>
                )}
                {!loading && daySlots !== undefined && daySlots !== null && daySlots.daySlots.length > 0 && (
                    <div  className={`${isSnippet ? styles.cardBodySnippet : styles.cardBody}`}>
                        <div className={`${styles.schedule} ${styles.scheduleFlexColumn}`}>
                          {!isSnippet &&(<div className={styles.scheduleTitleContainer}>
                                <div className={styles.scheduleTitle}>Available clinic appointments</div>
                                <div className={styles.pickerContainer}>
                                    {!(format(this.state.currentDate, 'yyyy-MM-dd') === this.state.daySlots?.daySlots[0].date) ? (
                                        <img
                                            onClick={this.datesBefore}
                                            className={`${styles.arrowIconsMobile} ${styles.rotateimg180}`}
                                            src={rightArrow}
                                            alt="left arrow icon"
                                        />
                                    ) : (
                                        <img
                                            onClick={this.datesBefore}
                                            className={`${styles.arrowIconsMobile} ${styles.disabled}`}
                                            src={leftArrowDisabled}
                                            alt="left arrow disabled icon"
                                        />
                                    )}
                                    {/* <span
                                        className={
                                            !this.state.showDatePicker
                                                ? `${styles.opacityShown} ${styles.scheduleDatePickerContainer}`
                                                : `${styles.opacityHidden} ${styles.scheduleDatePickerContainer}`
                                        }
                                        onClick={this.showDatePicker}
                                    >
                                        {format(this.state.currentDate, 'iii, MMM dd')}
                                        <img src="/-/media/themes/wellstar/org/wellstarorg/wellstartheme/images/vector.svg" alt="calendar icon" />
                                    </span> */}
                                    <img onClick={this.datesAfter} className={`${styles.arrowIconsMobile}`} src={rightArrow} alt="right arrow icon" />
                                </div>

                                <div className={`${styles.opacityShown} ${styles.scheduleDatePickerMui}`}>
                                    <div id={`${id}-datepicker-container`} className="d-inline-block h-100">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <ThemeProvider theme={theme}>
                                                <FormHelperText sx={{ display: 'none' }} id="schedule-preview-datepicker-input-label">
                                                    Starting appointment date
                                                </FormHelperText>
                                                <DatePicker
                                                    value={this.state.currentDate}
                                                    onChange={newValue => {
                                                        this.setCurrentDate(newValue);
                                                    }}
                                                    open={this.state.open}
                                                    onOpen={this.handleOpen}
                                                    onClose={this.handleClose}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            InputProps={{
                                                                'aria-describedby': 'schedule-preview-datepicker-input-label',
                                                                id: `${id}-datepicker-textfield`,
                                                                startAdornment: (
                                                                    <InputAdornment position="start" sx={{ marginRight: '4px' }}>
                                                                        <IconButton
                                                                            edge="start"
                                                                            onClick={this.handleOpen}
                                                                            aria-label="Launch appointment date picker"
                                                                            id={`${id}-datepicker-launch-calendar`}
                                                                            sx={{
                                                                                marginTop: '4px',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="/-/media/themes/wellstar/org/wellstarorg/wellstartheme/images/vector.svg"
                                                                                alt="calendar icon"
                                                                            />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                classes: { root: styles.scheduleDatePickerRoot },
                                                            }}
                                                        />
                                                    )}
                                                    InputProps={{
                                                        classes: { notchedOutline: styles.scheduleDatePickerInput },
                                                    }}
                                                    DialogProps={{
                                                        classes: { paper: styles.muiDialog },
                                                    }}
                                                    // clearable={true}
                                                    renderDay={this.tagAvailableDates}
                                                    // clearText={'Cancel'}
                                                    minDate={new Date()}
                                                    maxDate={add(new Date(), { years: 2 })}
                                                />
                                            </ThemeProvider>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div> )}
                            {!isSnippet 
                            ? <div className={styles.scheduleMatrixContainer}>
                                {!(format(this.state.currentDate, 'yyyy-MM-dd') === this.state.daySlots?.daySlots[0].date) ? (
                                    <img
                                        onClick={this.datesBefore}
                                        className={`${styles.arrowIcons} ${styles.rotateimg180}`}
                                        src={rightArrow}
                                        alt="left arrow icon"
                                    />
                                ) : (
                                    <img
                                        onClick={this.datesBefore}
                                        className={`${styles.arrowIcons} ${styles.disabled}`}
                                        src={leftArrowDisabled}
                                        alt="left arrow disabled icon"
                                    />
                                )}

                                <div className={styles.scheduleMatrix}>
                                    {currentSlots === undefined || currentSlots?.length === 0
                                        ? null
                                        : currentSlots?.map((day, index) =>
                                            day?.slots === undefined ? null : (
                                                <div key={day.date} className={`${styles.scheduleColumnContainer}`} id={`daycolumn${index}`}>
                                                    {this.formatDate(day.date)}
                                                    <ScheduleColumn
                                                        timeSelectedFunction={this.timeslotSelected}
                                                        day={day}
                                                        slots={day.slots}
                                                        rowHeight={
                                                            Math.max(...currentSlots.map(x => x?.slots.length)) < 3
                                                                ? Math.max(...currentSlots.map(x => x?.slots.length))
                                                                : 3
                                                        }
                                                        collapsed={this.state.collapsed}
                                                        moreSelectedFunction={this.moreSelectedFunction}
                                                        department={this.state.chosenDepartment}
                                                    />
                                                </div>
                                            )
                                        )}
                                </div>
                                <img onClick={this.datesAfter} className={`${styles.arrowIcons}`} src={rightArrow} alt="right arrow icon" />
                            </div> 
                            :<div className={styles.scheduleMatrixContainerSnippet}>
                            <div className={styles.scheduleMatrixSnippet}>
                                {currentSlots === undefined || currentSlots?.length === 0
                                    ? null
                                    : currentSlots?.map((day, index) =>
                                        day?.slots === undefined ? null : (
                                                <ScheduleColumn
                                                    timeSelectedFunction={this.timeslotSelected}
                                                    day={day}
                                                    slots={day.slots}
                                                    rowHeight={
                                                        Math.max(...currentSlots.map(x => x?.slots.length)) < 3
                                                            ? Math.max(...currentSlots.map(x => x?.slots.length))
                                                            : 3
                                                    }
                                                    collapsed={this.state.collapsed}
                                                    moreSelectedFunction={this.moreSelectedFunction}
                                                    department={this.state.chosenDepartment}
                                                    isSnippet
                                                />
                                        )
                                    )}
                            </div>
                        </div>  
                        }
                       { !isSnippet && (<span className={styles.scheduleLink} onClick={this.onBookAppointment}>
                                View full schedule
                            </span>)}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

interface IScheduleColumnProps {
    day: DaySlots;
    slots: Timeslot[];
    department: Department | null;
    timeSelectedFunction: Function;
    moreSelectedFunction: Function;
    rowHeight: number;
    collapsed: boolean;
    isSnippet?: boolean;
}
// left to do
// create the schedule matrix component (deals with setting collapsed and rowHeight, includes basically the entire table)
// make sure that the ScheduleColumn updates with prop update
export const ScheduleColumn: React.FunctionComponent<IScheduleColumnProps> = ({
                                                                                  day,
                                                                                  slots,
                                                                                  department,
                                                                                  timeSelectedFunction,
                                                                                  moreSelectedFunction,
                                                                                  rowHeight,
                                                                                  collapsed,
                                                                                  isSnippet
                                                                              }) => {
    useEffect(() => {
        // fill array with undefined until the length is rowHeight
        if (slots.length < rowHeight) {
            slots = slots.concat(new Array(rowHeight - slots.length).fill(undefined));
        }
    });
    const moreSelectedHandler = () => {
        if (typeof timeSelectedFunction == 'function' && timeSelectedFunction != null) moreSelectedFunction();
    };
    const timeSelectedHandler = (slot: Timeslot) => {
        let dateSelection = parseISO(day.date);
        dateSelection.setHours(slot.hour);
        dateSelection.setMinutes(parseInt(slot.hourText.split(':')[1]));
        dateSelection.setSeconds(0);
        if (typeof timeSelectedFunction == 'function' && timeSelectedFunction != null) timeSelectedFunction(dateSelection, slot, department);
    };
    const formatTime = (slot: Timeslot) => {
        let dateSelection = parseISO(day.date);
        dateSelection.setHours(slot.hour);
        dateSelection.setMinutes(parseInt(slot.hourText?.split(':')[1]));
        dateSelection.setSeconds(0);
        return format(dateSelection, 'h:mm a');
    };
    const formatDate = (date: string) => {
      let today = new Date();
      let dateTime = parseISO(date);
      if (isSameDay(today, dateTime)) {
          return (
              <span className={styles.scheduleColumnSnippet}>
                &nbsp;  {format(dateTime, ' MMM dd')}
              </span>
          );
      } else if (isTomorrow(dateTime)) {
          return (
              <span className={styles.scheduleColumnSnippet}>
                &nbsp;  {format(dateTime, ' MMM dd')}
              </span>
          );
      } else {
          format(dateTime, 'iii MMM dd');
          return (
              <span className={styles.scheduleColumnSnippet}>
                 &nbsp; {format(dateTime, ' MMM dd')}
              </span>
          );
      }
  };
    return (
      <>
      {!isSnippet ?  <div>
            <div className={`${styles.scheduleColumn}`}>
                {slots === undefined
                    ? null
                    : rowHeight > 4 && collapsed
                        ? Array.from(Array(4).keys()).map(i =>
                            i < 3 ? (
                                slots[i] !== undefined ? (
                                    <button key={slots[i].hourText} onClick={() => timeSelectedHandler(slots[i])} className={styles.scheduleButton}>
                                        {formatTime(slots[i])}
                                    </button>
                                ) : (
                                    <button key={i} className={styles.scheduleEmptyTimeSlot}>-</button>
                                )
                            ) : slots.length > 3 ? (
                                <button key={Math.random() * (3000 - 1) + 1} onClick={() => moreSelectedHandler()} className={styles.scheduleButton}>
                                    More
                                </button>
                            ) : (
                                <button key={i} className={styles.scheduleEmptyTimeSlot}>-</button>
                            )
                        )
                        : Array.from(Array(rowHeight).keys()).map(i =>
                            slots[i] !== undefined ? (
                              <button key={slots[i].hourText} onClick={() => timeSelectedHandler(slots[i])} className={styles.scheduleButton}>
                                    {formatTime(slots[i])}
                              </button>
                            ) : (
                                <button key={i} className={styles.scheduleEmptyTimeSlot}>-</button>
                            )
                        )}
            </div>
            <div className={`${styles.scheduleColumnMobile}`}>
                {slots === undefined
                    ? null
                    : Array.from(Array(Math.min(slots.length, 3)).keys()).map(i =>
                        slots[i] !== undefined ? (
                            <button key={slots[i].hourText} onClick={() => timeSelectedHandler(slots[i])} className={styles.scheduleButton}>
                                {formatTime(slots[i])}
                            </button>
                        ) : (
                            <button key={i} className={styles.scheduleEmptyTimeSlot}>-</button>
                        )
                    )}
            </div>
        </div>
      :
      <div className={`${styles.scheduleColumnSnippet}`}>
      {slots === undefined
          ? null
              : Array.from(Array(rowHeight).keys()).map(i =>
                  slots[i] !== undefined ? (
                    <button key={slots[i].hourText} onClick={() => timeSelectedHandler(slots[i])} className={styles.scheduleButtonKsuSnippet}>
                        {formatTime(slots[i])}
                        {formatDate(day.date)}
                    </button>
                  ) : (
                      <button key={i} className={styles.scheduleEmptyTimeSlot}>-</button>
                  )
              )
              }
  </div>  }
      </>
    );
};
