import React from 'react';
import './modal-footer.scss';

interface IModalFooterProps {
  nextEnabled: boolean;
  completedHandler: Function;
}

export default function ModalFooter(props: IModalFooterProps) {
  const { nextEnabled, completedHandler } = props;

  return (
    <div className="modal-footer-container">
      <div className="secure-booking-container">
        <i className="fa fa-lock" title="Secure booking lock icon" />
        <span>Secure booking</span>
      </div>
      <div className="buttons">
        <button
          type="button"
          onClick={() => completedHandler()}
          className={`next button ${!nextEnabled ? 'disabled' : ''}`}
          disabled={!nextEnabled}
        >
          Next Step
        </button>
      </div>
    </div>
  );
}
