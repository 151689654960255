import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { IProfileLocation } from '../../types/profile-locations';
import { Physician } from '../../types/physicians';
import { Timeslot } from '../../types/schedules';
import { QuestionnaireData } from '../../types/questionnaire';
import ModalHeader from './modal-header';
import ModalFooter from './modal-footer';
import LocationInfo from './location-info';
import TimeslotInfo from './timeslot-info';
import QuestionnaireBody from './questionnaire-body';
import './questionnaire-modal.scss';

interface IQuestionnaireModalProps {
  isShowing: boolean;
  dismissHandler: Function;
  completedHandler: Function;
  location: IProfileLocation;
  provider?: Physician;
  date?: Date;
  slot?: Timeslot;
}

export default function QuestionnaireModal(props: IQuestionnaireModalProps) {
  const {
    isShowing, dismissHandler, completedHandler, location, provider, date, slot,
  } = props;

  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [questionnaireData, setQuestionnaireData] = useState<QuestionnaireData[]>([]);

  const onClose = () => {
    setNextButtonEnabled(false);
    dismissHandler();
  };

  const onCompleted = () => {
    setNextButtonEnabled(false);
    completedHandler(questionnaireData);
  };

  return (
    isShowing && (
      <Modal
        open={isShowing}
        onClose={onClose}
      >
        <div className="questionnaire-modal-container">
          <ModalHeader closeHandler={onClose} />
          <LocationInfo location={location} />
          {provider && date && slot && (
            <TimeslotInfo provider={provider} date={date} slot={slot} />
          )}
          <QuestionnaireBody setNextButtonEnabled={setNextButtonEnabled} setQuestionnaireData={setQuestionnaireData} />
          <ModalFooter completedHandler={onCompleted} nextEnabled={nextButtonEnabled} />
        </div>
      </Modal>
    )
  );
}
