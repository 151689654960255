import React from 'react';
import WSLogo from './Inline.svg';
import KSLogo from './ksuLogoWhiteLtrs.png';
import './footer.scss';

function Footer() {
  return (
    <footer
      id="KSU-Footer"
      className="ksu-footer bd-header py-3 d-flex flex-column border-bottom"
    >
      <div className="container d-flex align-items-center">
        <div className="img-container-wellstar">
          <img src={WSLogo} alt="Wellstar logo" />
        </div>
        <div className="vertical-rule" />
        <div className="img-container-ksu">
          <img src={KSLogo} alt="KSU logo" />
        </div>
      </div>
      <div className="container d-flex align-items-center">
        <div className="copyright-container">
          <p>
            Copyright © 2023 Wellstar Health System. All Rights Reserved.
          </p>
          <p>
            Wellstar does not discriminate on, exclude people or treat
            them differently on the basis of race, color, national origin, age,
            disability, sex, gender identity or expression or any other type of
            discrimination prohibited by law.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
